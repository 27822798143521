import React from "react";
import { Link } from "react-router-dom";

class SignUpConfirmation extends React.Component {
  render() {
    var type = this.props.match ? this.props.match.params.type : this.props.type;
    var messageNode = null;
    if (type === "b") {
      messageNode = (
        <div>
          <h5>Thanks for Signing up with Buildstreet.</h5>

          <div style={{ marginTop: "20px" }}>
            We need you to confirm your email and phone number before accessing
            your account.
            <br />
            Please check your email and follow instructions mentioned to confirm
            your email. Click{" "}
            <Link to="/confirm/phone" className="btn-link">
              here
            </Link>{" "}
            to confirm your phone by entering OTP you received in the message.
          </div>
        </div>
      );
    } else if (type === "e") {
      messageNode = (
        <div>
          <h5>Thanks for Signing up with Buildstreet.</h5>

          <div style={{ marginTop: "20px" }}>
            We need you to confirm your email before accessing your account.
            <br />
            For confirming your email, Please check your email and follow
            instructions mentioned.
          </div>
        </div>
      );
    } else if (type === "p") {
      messageNode = (
        <div>
          <h5>Thanks for Signing up with Buildstreet.</h5>

          <div style={{ marginTop: "20px" }}>
            We need you to confirm phone number before accessing your account.
            <br />
            Click{" "}
            <Link to="/confirm/phone" className="btn-link">
              here
            </Link>{" "}
            to confirm your phone by entering OTP you received in the message.
          </div>
        </div>
      );
    }
    return (
      <section className="slice ">
        <div className="wp-section">
          <div className="container">
            <div className="row">
              <div style={{ textAlign: "center", marginTop: "100px" }}>
                {messageNode}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default SignUpConfirmation;
