import React from "react";
import AsyncSelect from "react-select/async";
import LocationService from "./../../../services/LocationService";

class LocationFinder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    return inputValue;
  };

  loadOptions = (location, callback) => {
    let { storeId } = this.props;
    if (location.length >= 3) {
      LocationService.searchLocation(location, storeId).then((options) => {
        callback(
          options.map((option) => {
            return option;
          })
        );
      });
    }
  };

  onChangeLocation = (location) => {
    let type = location.label.split("- in ")[1];
    if (type === "states") {
      this.props.onChangeState({
        label: location.value,
        value: location.value,
      });
    } else if (type === "districts") {
      this.findState(location.value, null, type);
    } else if (type === "zones") {
      this.findDistrict(location.value, null, type);
    } else if (type === "localities") {
      this.findZone(location.value);
    }
  };

  findState = (district, callback, type) => {
    let getZoneOptions = false;
    if (type === "districts") {
      getZoneOptions = true;
    }
    LocationService.getState(district).then((response) => {
      if (response.success) {
        this.props.onChangeState(
          {
            label: response.state,
            value: response.state,
          },
          false
        );
        this.props.onChangeDistrict(
          { label: district, value: district },
          getZoneOptions
        );
        if (callback) {
          callback(this.props);
        }
      }
    });
  };

  findDistrict = (zone, callback, type) => {
    let getLocalityOptions = false;
    if (type === "zones") {
      getLocalityOptions = true;
    }
    LocationService.getDistrict(zone).then((response) => {
      if (response.success) {
        this.findState(
          response.district,
          function (props) {
            props.onChangeZone(
              {
                label: zone,
                value: zone,
              },
              getLocalityOptions
            );
            if (callback) {
              callback(props);
            }
          },
          type
        );
      }
    });
  };

  findZone = (locality) => {
    LocationService.getZone(locality).then((response) => {
      if (response.success) {
        this.findDistrict(response.zone, function (props) {
          props.onChangeLocality({
            label: locality,
            value: locality,
          });
        });
      }
    });
  };

  render() {
    return (
      <div className="mr-1" style={{ width: "200px" }}>
        <div
          className="mb-1"
          style={{ fontWeight: 500, fontSize: "14px", width: "220px" }}
        >
          Search location
        </div>
        <AsyncSelect
          className="flex-fill mb-1"
          placeholder="Search location"
          onInputChange={this.handleInputChange}
          loadOptions={this.loadOptions}
          onChange={this.onChangeLocation}
        />
        <hr />
      </div>
    );
  }
}

export default LocationFinder;
