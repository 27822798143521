import React from "react";
import Sidebar from "react-sidebar";
import MobileLeftNav from "./MobileLeftNav";
import UserBanner from "../../widgets/UserBanner";

class SideBarMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { sidebarOpen: false, sidebarDocked: false };
  }

  onSetSidebarOpen = () => {
    this.props.onClose();
  };

  render() {
    var sidebarContent = (
      <div>
        <UserBanner user={this.props.user} />
        <MobileLeftNav onClick={this.props.onClose} user={this.props.user} />
      </div>
    );

    return (
      <Sidebar
        sidebar={sidebarContent}
        open={this.props.show}
        touch={true}
        onSetOpen={this.onSetSidebarOpen}
        styles={{
          root: { top: "50px" },
          sidebar: {
            background: "#eee",
            zIndex: 1001,
            padding: "10px",
            position: "fixed",
            top: "50px",
          },
        }}
      >
        <span />
      </Sidebar>
    );
  }
}
export default SideBarMenu;
