import React from "react";
import VirtualBuyerService from "../../../services/VirtualBuyerService";
import ProductAsRequirement from "./ProductAsRequirement";
import VirtualBuyerCart from "./VirtualBuyerCart";
import { connect } from "react-redux";
import {
  addToCart,
  setStore,
  clearCart,
  setUserOnStore,
} from "../../../state/actions/cart";
import history from "../../../utils/History";
import ShopProductsMenu from "../Shop/ShopProductsMenu";
import http from "../../../../src/services/Ajax";
import ShopProductsFilter from "../Shop/ShopProductsFilter";
import StorageService from "../../../services/StorageService";
import Actions from "../../../state/actions";
import BannerImages from "./BannerImages";

class VirtualStore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: [],
      products: [],
      subtypes: [],
      brands: [],
      filters: [],
      checkedFilters: {},
    };
  }

  componentDidMount = () => {
    this.props.setUserOnStore(true);
    let { code } = this.props.match.params;

    VirtualBuyerService.getStoreDetails(code)
      .then((response) => {
        if (response) {
          let { storePhone, storeId, storeName, bannerImages = [] } = response;
          this.props.setStore({
            storeId,
            code,
            storeName,
            storePhone,
          });
          if (StorageService.loggedIn()) {
            this.addStoreId(storeId);
          }
          if (this.props.serviceArea) {
            this.init(this.props.serviceArea);
          }
          this.setState({ bannerImages });
        }
      })
      .catch((e) => {
        this.props.toastMessage(
          "Sorry! Looks like the link you have followed is not valid anymore.",
          "error",
          10
        );
      });
  };

  componentWillUnmount = () => {
    this.props.setUserOnStore(false);
  };

  init = (serviceArea) => {
    this.getSubtypes(serviceArea);
    // this.getVirtualBuyerProducts(serviceArea);
  };

  componentWillReceiveProps = (nextProps) => {
    let { serviceArea } = nextProps;

    if (serviceArea !== this.props.serviceArea) {
      this.init(serviceArea);
    }
  };

  addStoreId = (storeId) => {
    VirtualBuyerService.addStoreId(storeId).then((response) => {
      if (response.success) {
        console.log("Store has been added as visited store to the user");
      } else {
        this.props.toastMessage(
          "Sorry! Looks like the link you have followed is not valid anymore.",
          "error",
          10
        );
      }
    });
  };

  setFilters = (attribute, value) => {
    let { checkedFilters = {} } = this.state;

    if (attribute === "subtype") {
      checkedFilters = {};
      checkedFilters[attribute] = value;
    } else {
      let attrValues = checkedFilters[attribute] || [];
      let index = attrValues.indexOf(value);
      if (index !== -1) {
        attrValues.splice(index, 1);
      } else {
        attrValues.push(value);
      }
      checkedFilters[attribute] = [...attrValues];
    }
    return checkedFilters;
  };

  handleFilterChange = (attribute, value) => {
    let checkedFilters = this.setFilters(attribute, value);
    this.setState(
      {
        checkedFilters: { ...checkedFilters },
      },
      () => {
        if (attribute === "subtype") {
          this.getBrands();
        }
        if (attribute === "brand") {
          this.getBrandFilters();
        }
        this.setState({ currentPage: 1, products: [] }, () => {
          this.getVirtualBuyerProducts(this.props.serviceArea);
        });
      }
    );
  };

  getSubtypes = (serviceArea) => {
    let { storeId } = this.props;
    let api = "/api/v2/sku/stock/subtypes";

    http.post(api, { storeId, storeType: "virtual" }).then((response) => {
      let { subtypes = [] } = response;
      let { checkedFilters = {} } = this.state;
      if (!checkedFilters.subtype && subtypes.length >= 1) {
        checkedFilters.subtype = subtypes[0];
      }
      this.setState(
        {
          subtypes: subtypes,
          checkedFilters: { ...checkedFilters },
        },
        this.getBrands,
        this.getVirtualBuyerProducts(serviceArea, subtypes)
      );
    });
  };

  getBrands = () => {
    let { storeId } = this.props;
    let api = "/api/v2/sku/stock/brands";
    let filter = {
      subtype: this.state.checkedFilters.subtype,
      storeId,
      storeType: "virtual",
    };
    http.post(api, filter).then((response) => {
      this.setState(
        {
          brands: response.brands,
        },
        this.getBrandFilters
      );
    });
  };

  getBrandFilters = () => {
    if (!this.state.checkedFilters.subtype) {
      this.setState({ filters: {} });
      return;
    }
    let { storeId } = this.props;
    let api = "/api/v2/sku/stock/filters";
    let filter = {
      subtype: this.state.checkedFilters.subtype,
      brand: this.state.checkedFilters.brands,
      storeId,
      storeType: "virtual",
    };

    http.post(api, filter).then((response) => {
      this.setState({ filters: response.filters });
    });
  };

  getVirtualBuyerProducts = (serviceArea, subtypes) => {
    let { checkedFilters = {} } = this.state;
    checkedFilters = JSON.parse(JSON.stringify(checkedFilters));
    if (!checkedFilters.subtype) checkedFilters.subtype = subtypes[0];
    let { storeId } = this.props;
    let updateLog = false;
    let isLogAdded = StorageService.getIsLogAdded();

    if (!isLogAdded) {
      updateLog = true;
    }

    VirtualBuyerService.getVirtualStoreStocks(storeId, {
      serviceArea,
      checkedFilters,
      updateLog,
    })
      .then((response) => {
        if (response.success) {
          StorageService.updateIsLogAdded();
          let products = response.data;
          if (products && products.length > 0) {
            this.setState({ products: products });
          }
        } else {
          this.props.toastMessage(response.message, "error", 10);
          this.props.clearCart();
          this.setState({ products: [] });
        }
      })
      .catch((e) => {
        this.props.toastMessage(e.message, "error", 10);
      });
  };

  showCart = () => {
    let { code, storeName } = this.props;
    let { cart } = this.state;
    if (cart.length) {
      history.push("/" + code + "/" + storeName + "/cart");
    }
  };

  // closeCart = () => {
  //   this.setState({ showCart: false });
  //   this.resetCart();
  // };

  addToCart = (requirement, quantity, priceWithTax, totalPrice) => {
    requirement = JSON.parse(JSON.stringify(requirement));
    requirement.quantity = quantity;
    requirement.priceWithTax = priceWithTax;
    requirement.totalPrice = totalPrice;
    this.props.addToCart(requirement);
    this.props.toastMessage(
      "Product has been added to the cart!",
      "success",
      1
    );
  };

  // resetCart = () => {
  //   this.setState({ cart: [] });
  // };

  render() {
    let { serviceArea, storeId, code, storeName } = this.props;
    let { bannerImages = [] } = this.state;
    if (!serviceArea) {
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "calc(100vh - 50px)" }}
        >
          Please select your location to enter the store.
        </div>
      );
    }
    let { products } = this.state;

    return (
      <div>
        {this.state.subtypes.length >= 1 ? (
          <div>
            {bannerImages.length >= 1 && (
              <BannerImages bannerImages={bannerImages} />
            )}
            <br />
            <VirtualBuyerCart
              code={code}
              storeName={storeName}
              updateCart={this.updateCart}
            />
            <div className="col-md-12 mt-1">
              <ShopProductsMenu
                subtypes={this.state.subtypes}
                checkedFilters={this.state.checkedFilters}
                handleMenuChange={this.handleFilterChange}
              />
            </div>

            <div className="col-md-12">
              <div className="col-md-3 border border-dark py-3">
                <ShopProductsFilter
                  brands={this.state.brands}
                  filters={this.state.filters}
                  checkedFilters={this.state.checkedFilters}
                  handleFilterChange={this.handleFilterChange}
                />
              </div>
              {products.length >= 1 ? (
                <div className="col-md-9">
                  {products.map((product, index) => {
                    return (
                      <ProductAsRequirement
                        requirement={product}
                        index={index}
                        changeRequirementValue={
                          this.props.changeRequirementValue
                        }
                        addToCart={this.addToCart}
                        fromComponent={"VIRTUAL_STORE"}
                        storeId={storeId}
                        updateCart={this.updateCart}
                        serviceArea={serviceArea}
                      />
                    );
                  })}
                </div>
              ) : (
                <div className="text-center">
                  <p className="mt-5 px-5">
                    No products available for your location
                  </p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="text-center">
                <p className="mt-5 px-5">No products available in your store</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function select(state) {
  return {
    cart: state.cart ? state.cart.items : [],
    serviceArea: state.cart.serviceArea ? state.cart.serviceArea : null,
    storeId: state.cart && state.cart.store ? state.cart.store.storeId : null,
    code: state.cart && state.cart.store ? state.cart.store.code : null,
    storeName:
      state.cart && state.cart.store ? state.cart.store.storeName : null,
    storePhone:
      state.cart && state.cart.store ? state.cart.store.storePhone : null,
  };
}

function actions(dispatch) {
  return {
    addToCart: (product) => dispatch(addToCart(product)),
    clearCart: () => dispatch(clearCart()),
    setStore: (store) => dispatch(setStore(store)),
    toastMessage: (message, type, duration) => {
      dispatch(Actions.toastMessage(message, type, duration));
    },
    setUserOnStore: (userIn) => dispatch(setUserOnStore(userIn)),
  };
}

export default connect(select, actions)(VirtualStore);
