import React from "react";
import { connect } from "react-redux";
import SideBarMenu from "./SideBarMenu";
import responsive from "../../utils/Responsive";
import ServiceAreaPopUp from "../rb/VirtualBuyer/ServiceAreaPopUp";
import history from "../../utils/History";
import { resetCart } from "../../state/actions/cart";
import StorageService from "../../services/StorageService";
import DesktopLoggedInHeader from "./DesktopLoggedInHeader";
import StoreName from "../../widgets/headers/StoreName";

class LoggedInHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var mql = responsive.smallScreenMedia();
    mql.addListener(this.mediaQueryChanged);
    this.setState({ mql: mql, showMobileNav: mql.matches });
  }

  exitStore = () => {
    this.props.resetCart();
    history.push("/dashboard/stores");
  };

  componentWillUnmount() {
    this.state.mql.removeListener(this.mediaQueryChanged);
  }

  mediaQueryChanged = () => {
    this.setState({ showMobileNav: this.state.mql.matches });
  };

  toggleMobileMenu = () => {
    var showMobileMenu = !this.state.showMobileMenu;
    this.setState({ showMobileMenu: showMobileMenu });
  };

  onCloseMobileMenu = () => {
    this.setState({ showMobileMenu: false });
  };

  onClickCity = () => {
    this.setState({ showCityModal: true });
  };

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  removeUnderScore = (storeName) => {
    if (storeName) {
      let modifiedName = storeName.replace(/_/g, " ");
      return modifiedName;
    }
  };

  render() {
    var user = this.props.user;
    let isVirtualStore = this.props.userOnStore;

    if (isVirtualStore) {
      if (this.state.showMobileNav) {
        return (
          <div>
            <div>
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle"
                  style={{ borderColor: "#fff", margin: "10px 15px" }}
                  onClick={this.toggleMenu}
                >
                  <i
                    className="fa fa-bars icon-custom"
                    style={{ color: "#fff" }}
                  />
                </button>

                <a
                  className="navbar-brand"
                  href="/"
                  style={{ margin: "10px 0", paddingLeft: "15px" }}
                >
                  <img
                    src="/images/logos/sf-logo.png"
                    style={{ height: "32px" }}
                    alt="Storefronts"
                  />
                </a>
              </div>

              <StoreName
                pathname={this.props.pathname}
                storeName={this.props.storeName}
              />

              <div
                className={`navbar-collapse collapse ${
                  this.state.showMenu ? "in" : ""
                }`}
              >
                <ul className="nav navbar-nav navbar-right">
                  <li className="dropdown">
                    <ServiceAreaPopUp open={this.state.showMenu} />
                  </li>

                  <li className="dropdown">
                    <a href="#">
                      <i
                        className="fa fa-phone"
                        style={{ marginRight: "5px" }}
                      />
                      {this.props.storePhone
                        ? this.props.storePhone
                        : "+91 81249 68000"}
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ textTransform: "none" }}
                      onClick={this.exitStore}
                    >
                      <i
                        className="fa fa-sign-out"
                        style={{ marginRight: "5px", fontWeight: 400 }}
                      />
                      Exit this store
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <DesktopLoggedInHeader
            isVirtualStore={isVirtualStore}
            storeName={this.removeUnderScore(this.props.storeName)}
            storePhone={this.props.storePhone}
            exitStore={this.exitStore}
          />
        );
      }
    } else {
      if (this.state.showMobileNav) {
        return (
          <div>
            <div className="navbar-header" style={{ padding: "5px" }}>
              <div className="col-xs-2" style={{ padding: "10px 0 0 25px" }}>
                <a onClick={this.toggleMobileMenu} className="link">
                  <i
                    className="fa fa-bars"
                    style={{ fontSize: "18px", color: "#fff" }}
                  />
                </a>
              </div>
              <div className="col-xs-9" style={{ padding: "3px 0px 0px 0px" }}>
                <a href="/">
                  <img
                    src="/images/logos/sf-logo.png"
                    style={{ height: "32px" }}
                    alt="Storefronts"
                  />
                </a>
              </div>
            </div>

            <div
              className="d-flex justify-content-between align-items-center"
              style={{
                padding: "0 15px",
              }}
            >
              <div>
                <ServiceAreaPopUp
                  open={this.state.showMenu}
                  style={{
                    padding: 10,
                  }}
                />
              </div>

              <div>
                <a href="#" style={{ color: "#fff" }}>
                  <i className="fa fa-phone" style={{ marginRight: "5px" }} />
                  {this.props.storePhone
                    ? this.props.storePhone
                    : "+91 81249 68000"}
                </a>
              </div>
            </div>

            <SideBarMenu
              show={this.state.showMobileMenu}
              onClose={this.onCloseMobileMenu}
              user={user}
            />
          </div>
        );
      } else {
        return (
          <DesktopLoggedInHeader
            isVirtualStore={isVirtualStore}
            storeName={this.props.storeName}
            storePhone={this.props.storePhone}
            exitStore={this.exitStore}
          />
        );
      }
    }
  }
}

function data(state) {
  return {
    user: state.user,
    userOnStore:
      state.cart && state.cart.userOnStore ? !!state.cart.userOnStore : null,
  };
}

function actions(dispatch) {
  return {
    resetCart: () => dispatch(resetCart()),
  };
}

export default connect(data, actions)(LoggedInHeader);
