import React from "react";
import history from "../../../utils/History";

class EmptyCart extends React.PureComponent {
  returnToShop = () => {
    let { code, storeName } = this.props;
    history.push("/" + code + "/" + storeName);
  };

  render() {
    return (
      <section className="slice sct-color-1">
        <div
          style={{ height: "calc(100vh - 100px)" }}
          className="container d-flex justify-content-center align-items-center"
        >
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="text-center">
                <h5 style={{ textTransform: "none" }}>
                  Your cart is empty.{" "}
                  <a className="btn-link" onClick={this.returnToShop}>
                    Click
                  </a>{" "}
                  here to go back.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default EmptyCart;
