import React from "react";
import { NavLink } from "react-router-dom";
import LogOut from "../auth/LogOut";

class LeftNav extends React.Component {
  render() {
    let { userType } = this.props;
    if (userType === "C") {
      return (
        <nav className="navbar">
          <div className="left-navbar">
            <ul className="nav nav-stacked">
              <li>
                <NavLink
                  to="/dashboard/stores"
                  activeClassName="active-nav"
                  className="left-link"
                >
                  <i
                    className="fa fa-building"
                    aria-hidden="true"
                    style={{ marginRight: "10px" }}
                  />
                  Visited Stores
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/dashboard/chat"
                  activeClassName="active-nav"
                  className="left-link"
                >
                  <i
                    className="fa fa-comment"
                    style={{ marginRight: "10px" }}
                  />
                  Chat
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/dashboard/orders"
                  activeClassName="active-nav"
                  className="left-link"
                >
                  <i
                    className="fa fa-list"
                    style={{ marginRight: "10px" }}
                  />
                  Orders
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/dashboard/settings"
                  className="left-link"
                  activeClassName="active-nav"
                >
                  <i className="fa fa-cog" style={{ marginRight: "10px" }} />
                  Settings
                </NavLink>
              </li>
              <li>
                <LogOut routeTo="/signin" />
              </li>
            </ul>
          </div>
        </nav>
      );
    } else if (userType === "B") {
      return (
        <nav className="navbar">
          <div className="left-navbar">
            <ul className="nav nav-stacked">
              <li>
                <NavLink
                  to="/dashboard/stores"
                  activeClassName="active-nav"
                  className="left-link"
                >
                  <i
                    className="fa fa-building"
                    style={{ marginRight: "10px" }}
                  />
                  Stores
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/dashboard/chat"
                  activeClassName="active-nav"
                  className="left-link"
                >
                  <i
                    className="fa fa-comment"
                    style={{ marginRight: "10px" }}
                  />
                  Chat
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/dashboard/settings"
                  className="left-link"
                  activeClassName="active-nav"
                >
                  <i className="fa fa-cog" style={{ marginRight: "10px" }} />
                  Settings
                </NavLink>
              </li>
              <li>
                <LogOut routeTo="/signin" />
              </li>
            </ul>
          </div>
        </nav>
      );
    } else if (userType === "A") {
      return (
        <nav className="navbar">
          <div className="left-navbar">
            <ul className="nav nav-stacked">
              <li>
                <NavLink
                  to="/dashboard/skus"
                  activeClassName="active-nav"
                  className="left-link"
                >
                  <i
                    className="fa fa-list-ul"
                    style={{ marginRight: "10px" }}
                  />
                  SKUS
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/dashboard/non-serviceable"
                  activeClassName="active-nav"
                  className="left-link"
                >
                  <i className="fa fa-times" style={{ marginRight: "10px" }} />
                  Non serviceable Reqs
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/dashboard/orders"
                  activeClassName="active-nav"
                  className="left-link"
                >
                  <i className="fa fa-bars" style={{ marginRight: "10px" }} />
                  Orders
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/dashboard/bid-requests"
                  activeClassName="active-nav"
                  className="left-link"
                >
                  <i
                    className="fa fa-exchange"
                    style={{ marginRight: "10px" }}
                  />
                  Bid Requests
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/dashboard/crm"
                  activeClassName="active-nav"
                  className="left-link"
                >
                  <i className="fa fa-users" style={{ marginRight: "10px" }} />
                  Manage leads
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/settings" className="left-link">
                  <i className="fa fa-cog" style={{ marginRight: "10px" }} />
                  Settings
                </NavLink>
              </li>
              <li>
                <LogOut routeTo="/signin" />
              </li>
            </ul>
          </div>
        </nav>
      );
    } else if (userType === "O") {
      return (
        <nav className="navbar">
          <div className="left-navbar">
            <ul className="nav nav-stacked">
              <li>
                <NavLink
                  to="/dashboard/leads"
                  activeClassName="active-nav"
                  className="left-link"
                >
                  <i
                    className="fa fa-list-ul"
                    style={{ marginRight: "10px" }}
                  />
                  Leads
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/dashboard/non-serviceable"
                  activeClassName="active-nav"
                  className="left-link"
                >
                  <i className="fa fa-times" style={{ marginRight: "10px" }} />
                  Non serviceable Reqs
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/dashboard/orders"
                  activeClassName="active-nav"
                  className="left-link"
                >
                  <i className="fa fa-bars" style={{ marginRight: "10px" }} />
                  Orders
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/dashboard/bid-requests"
                  activeClassName="active-nav"
                  className="left-link"
                >
                  <i
                    className="fa fa-exchange"
                    style={{ marginRight: "10px" }}
                  />
                  Bid Requests
                </NavLink>
              </li>

              <li>
                <LogOut routeTo="/signin" />
              </li>
            </ul>
          </div>
        </nav>
      );
    } else {
      return null;
    }
  }
}

export default LeftNav;
