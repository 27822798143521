import React from "react";
import { connect } from "react-redux";
import Actions from "../../state/actions";

class LinkToModal extends React.Component {
  showModal = () => {
    var props = this.props;
    props.showModal(props.modalType, props.modalProps, props.modalSize);
  };

  render() {
    var className = this.props.className || "btn-link";
    return (
      <a
        onClick={this.showModal}
        className={className}
        style={this.props.style}
      >
        {this.props.children}
      </a>
    );
  }
}

var actions = function(dispatch) {
  return {
    showModal: (type, props, size) =>
      dispatch(Actions.showModal(type, props, size))
  };
};

export default connect(
  null,
  actions
)(LinkToModal);
