import React from "react";
import {connect} from "react-redux"


function StoreName({ pathname, storeName, isVirtualStore }) {
  if (!isVirtualStore) {
    return null;
  }

  if (storeName) {
    storeName = storeName.replace(/_/g, " ");
    storeName = storeName.replace(/-/g, " ");
  }

  return (
    <div className="nav navbar-nav">
      <span>
        <h5
          style={{
            color: "#fff",
            marginBottom: 0,
            padding: "15px 30px",
            fontSize: 22,
            textAlign: "center",
          }}
        >
          {storeName}
        </h5>
      </span>
    </div>
  );
}

function select(state) {
  return {
    isVirtualStore:
      state.cart && state.cart.userOnStore ? !!state.cart.userOnStore : null,
  };
}

export default connect(select)(StoreName);
