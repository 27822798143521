import StorageService from "../../services/StorageService";

const initialState = { items: [], serviceArea: null };

function cart(state = initialState, action = null) {
  if (action.type === "ADD_TO_CART") {
    console.log("works");
    let items = state.items || [];
    let skuId = action.product.sku._id;
    let index = items.findIndex((p) => p.sku._id === skuId);
    if (index !== -1) {
      items[index] = action.product;
    } else {
      items.push(action.product);
    }
    StorageService.addToLocalStorage("cart", items);
    return {
      ...state,
      items: [...items],
    };
  }

  if (action.type === "REMOVE_FROM_CART") {
    let items = state.items;
    let skuId = action.skuId;
    let index = items.findIndex((p) => p.sku._id === skuId);
    if (index !== -1) {
      items.splice(index, 1);
    }
    StorageService.addToLocalStorage("cart", items);
    return {
      ...state,
      items: [...items],
    };
  }

  if (action.type === "CLEAR_CART") {
    StorageService.addToLocalStorage("cart", []);
    return { ...state, items: [] };
  }

  if (action.type === "ADD_SERVICE_AREA") {
    StorageService.addToLocalStorage("serviceArea", action.serviceArea);
    return { ...state, serviceArea: { ...action.serviceArea }, items: [] };
  }

  if (action.type === "HYDRATE") {
    let serviceArea = StorageService.getFromLocalStorage("serviceArea");
    let cart = StorageService.getFromLocalStorage("cart");
    let store = StorageService.getFromLocalStorage("store");
    return {
      ...state,
      serviceArea,
      items: cart,
      store: store || null,
    };
  }

  if (action.type === "RESET_CART") {
    StorageService.removeItemsFromLocalStorage(["store", "cart"]);
    return { ...state, items: [], store: null };
  }

  if (action.type === "SET_STORE") {
    if (!state.store || state.store.storeId !== action.store.storeId) {
      StorageService.addToLocalStorage("store", action.store);
      StorageService.addToLocalStorage("cart", []);
      return {
        ...state,
        store: action.store,
        items: [],
      };
    } else if (state.store && state.store.storeId === action.store.storeId) {
      return {
        ...state,
        store: action.store,
      };
    } else {
      return state;
    }
  }

  if (action.type === "SET_USER_ON_STORE") {
    return {
      ...state,
      userOnStore: action.userIn,
    };
  }
  return state;
}

export default cart;
