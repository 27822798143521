import React from "react";
import { connect } from "react-redux";
import SettingsForm from "./SettingsForm";
import Validation from "../../utils/ValidationUtils";
import { fetchSettings, saveUserData } from "../../state/actions/settings";
import { toastMessage } from "../../state/actions/toaster";
import ModalN from "../../widgets/modal/ModalN";
import ChangePassword from "./ChangePassword";

class SettingsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.fetchSettings();
  }

  saveSettings = formData => {
    this.setState({ error: "" });
    var errorMessage = Validation.validateSettings(formData);
    if (errorMessage) {
      this.props.toastMessage(errorMessage, "error", 3);
      return;
    }
    this.props.saveSettings(formData, res => {
      if (res.success) {
        this.props.toastMessage(
          "Done! Your profile has been updated.",
          "success",
          3
        );
      } else {
        var message =
          res.message ||
          "Error updating your profile. Please try again later or contact us in the chat.";
        this.props.toastMessage(message, "error", 3);
      }
    });
  };

  startChangePassword = () => {
    this.setState({ showCPModal: true });
  };

  cancelChangePassword = () => {
    this.setState({ showCPModal: false });
  };

  render() {
    var user = this.props.settings;
    if (!user) {
      return null;
    }
    return (
      <div>
        <h5 className="dashboard-title">Settings</h5>
        <SettingsForm user={user} onSubmit={this.saveSettings} />

        <div
          className="col-md-6 col-md-offset-3"
          style={{ textAlign: "center", marginTop: "50px" }}
        >
          {/*<LinkToModal*/}
          {/*  className="btn-link"*/}
          {/*  style={{ margin: "20px 0" }}*/}
          {/*  modalType="CHANGE_PASSWORD"*/}
          {/*  modalProps={{ userId: user._id }}*/}
          {/*>*/}
          {/*  */}
          {/*</LinkToModal>*/}

          <a
            className="btn-link "
            style={{ margin: "20px 0" }}
            onClick={this.startChangePassword}
          >
            Change Password
          </a>
        </div>

        <ModalN
          title="Change Password"
          show={this.state.showCPModal}
          close={this.cancelChangePassword}
        >
          <ChangePassword onComplete={this.cancelChangePassword} />
        </ModalN>
      </div>
    );
  }
}

function data(state) {
  return {
    settings: state.user.settings
  };
}

function actions(dispatch) {
  return {
    saveSettings: (data, cb) => {
      dispatch(saveUserData(data, cb));
    },
    fetchSettings: () => dispatch(fetchSettings()),
    toastMessage: (message, type, duration) => {
      dispatch(toastMessage(message, type, duration));
    }
  };
}

export default connect(
  data,
  actions
)(SettingsView);
