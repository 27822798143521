import http from "../services/Ajax";

class LocationService {
  static searchLocation = (location, storeId) => {
    return http
      .get(
        "/api/v2/locations/search-location?location=" +
          location +
          "&storeId=" +
          storeId
      )
      .then((response) => {
        if (response.success) {
          return response.data.options;
        } else {
          throw new Error();
        }
      });
  };

  static getState = (district) => {
    return http
      .get("/api/v2/locations/get-state?district=" + district)
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  };

  static getDistrict = (zone) => {
    return http
      .get("/api/v2/locations/get-district?zone=" + zone)
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  };

  static getZone = (locality) => {
    return http
      .get("/api/v2/locations/get-zone?locality=" + locality)
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  };
}
export default LocationService;
