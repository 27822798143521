const initialState = {
    modalType: null,
    modalProps: {},
    modalSize: null
};

function modal(state = initialState, action = null) {
    switch (action.type) {
        case 'SHOW_MODAL':
            return {
                modalType: action.modalType,
                modalProps: action.modalProps,
                modalSize: action.modalSize
            };
        case 'HIDE_MODAL':
            return initialState;
        default:
            return state
    }
}

export default modal;