import http from "./Ajax";
import StorageService from "./StorageService";

class VirtualBuyerService {
  static calculatePricingForVirtualBuyer(
    quantity,
    skuId,
    serviceArea,
    from,
    storeId
  ) {
    return http
      .post("/api/v2/virtual-buyer/sku/pricing", {
        quantity,
        skuId,
        serviceArea,
        from,
        storeId,
      })
      .then((response) => {
        return response;
      });
  }

  static createOrdersVirtualBuyer(orderData) {
    return http
      .post("/api/v2/virtual-buyer/orders", orderData)
      .then((response) => {
        if (response.success) {
          return response.data;
        } else {
          throw new Error();
        }
      });
  }

  static getStoreDetails(code) {
    return http
      .get("/api/v2/virtual-buyer/" + code + "/store-details")
      .then((response) => {
        if (response.success) {
          return response.data;
        } else {
          throw new Error();
        }
      });
  }

  static addStoreId(storeId) {
    return http
      .post("/api/v2/virtual-buyer/add-store-id", { storeId })
      .then((response) => {
        if (response) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static getVirtualStores() {
    return http.post("/api/v2/virtual-buyer/get-stores").then((response) => {
      if (response.success) {
        return response;
      } else {
        throw new Error();
      }
    });
  }

  static getShippingAddresses(serviceArea) {
    let api = "/api/v2/shipping-address";
    if (serviceArea) {
      api += "?state=" + serviceArea.state;
      api += "&district=" + serviceArea.district;
      api += "&zone=" + serviceArea.zone;
      api += "&locality=" + serviceArea.locality;
    }
    return http.get(api).then((response) => {
      if (response.success) {
        return response;
      } else {
        throw new Error();
      }
    });
  }

  static addShippingAddress(data) {
    return http
      .post("/api/v2/shipping-address", {
        data,
      })
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static editShippingAddress(data, id) {
    return http
      .patch("/api/v2/shipping-address", {
        data,
        id,
      })
      .then((response) => {
        if (response.success) {
          return response;
        } else {
          throw new Error();
        }
      });
  }

  static editServiceLocations(serviceAreas, storeId) {
    return http
      .post("/api/v2/virtual-stores/edit-serviceArea", {
        serviceAreas,
        storeId,
      })
      .then((response) => {
        if (response.success) {
          return response.success;
        } else {
          throw new Error();
        }
      });
  }

  static getDistrictOptions = () => {
    return http.get("/api/v2/locations/districts").then((response) => {
      if (response.success) {
        return response.data;
      } else {
        throw new Error();
      }
    });
  };

  static getVirtualStoreStateOptions = (storeId) => {
    return http.get("/api/v2/locations/states/" + storeId).then((response) => {
      if (response.success) {
        return response.data;
      } else {
        throw new Error();
      }
    });
  };

  static getVirtualStoreDistrictOptions = (storeId, state) => {
    return http
      .get(
        "/api/v2/locations/virtual-store/districts?state=" +
          state +
          "&storeId=" +
          storeId
      )
      .then((response) => {
        if (response.success) {
          return response.data;
        } else {
          throw new Error();
        }
      });
  };

  static getZoneOptions = (district) => {
    return http
      .get("/api/v2/locations/zones?district=" + district)
      .then((response) => {
        if (response.success) {
          let zoneOptions = response.data;
          return zoneOptions.map((l) => ({ value: l, label: l }));
        } else {
          throw new Error();
        }
      });
  };

  static getVirtualStoreZoneOptions = (district, storeId) => {
    return http
      .get(
        "/api/v2/locations/virtual-store/zones?district=" +
          district +
          "&storeId=" +
          storeId
      )
      .then((response) => {
        if (response.success) {
          let zoneOptions = response.data;
          return zoneOptions.map((l) => ({ value: l, label: l }));
        } else {
          throw new Error();
        }
      });
  };

  static getLocalityOptions = (zone) => {
    return http
      .get("/api/v2/locations/localities?zone=" + zone)
      .then((response) => {
        if (response.success) {
          let localityOptions = response.data;
          return localityOptions.map((l) => ({ value: l, label: l }));
        } else {
          throw new Error();
        }
      });
  };

  static getVirtualStoreLocalityOptions = (zone, storeId) => {
    return http
      .get(
        "/api/v2/locations/virtual-store/localities?zone=" +
          zone +
          "&storeId=" +
          storeId
      )
      .then((response) => {
        if (response.success) {
          let localityOptions = response.data;
          return localityOptions.map((l) => ({ value: l, label: l }));
        } else {
          throw new Error();
        }
      });
  };

  static getVirtualStoreStocks(storeId, data) {
    return http
      .post("/api/v2/virtual-stores/" + storeId + "/get-stocks", data)
      .then((response) => {
        if (response.success) {
          StorageService.updateIsLogAdded();
          return response;
        } else if (response.message) {
          return response;
        }
      });
  }

  static getServiceAreas() {
    return http.get("/api/v2/locations").then((response) => {
      if (response.success) {
        return response.data;
      } else {
        throw new Error();
      }
    });
  }

  static getOrders() {
    return http.get("/api/v2/virtual-store/orders/").then((data) => {
      if (data.success) {
        return data.data;
      } else {
        throw new Error();
      }
    });
  }
}

export default VirtualBuyerService;
