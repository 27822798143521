import React from "react";
import StorageService from "../../../src/services/StorageService";
import ServiceAreaPopUp from "../../components/rb/VirtualBuyer/ServiceAreaPopUp";
import VirtualBuyerService from "../../services/VirtualBuyerService";
import { Link } from "react-router-dom";
import StoreName from "./StoreName";
import { connect } from "react-redux";

class AnonymousHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { serviceArea: {} };
  }

  onClickCity = () => {
    this.setState({ showCityModal: true });
  };

  componentDidMount() {
    var city = StorageService.getCity();
    this.setState({ city: city });
  }

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  render() {
    let isVirtualStore = this.props.userOnStore;
    return (
      <div className="">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle"
            style={{ borderColor: "#fff", margin: "10px 15px" }}
            onClick={this.toggleMenu}
          >
            <i className="fa fa-bars icon-custom" style={{ color: "#fff" }} />
          </button>

          <a
            className="navbar-brand"
            href="/"
            style={{ margin: "10px 0", paddingLeft: "15px" }}
          >
            <img
              src="/images/logos/sf-logo.png"
              style={{ height: "32px" }}
              alt="Storefronts"
            />
          </a>
        </div>

        <StoreName
          pathname={this.props.pathname}
          storeName={this.props.storeName}
        />

        {!this.state.showMenu && !this.props.serviceArea && isVirtualStore && (
          <ServiceAreaPopUp open={this.state.showMenu} />
        )}

        <div
          className={`navbar-collapse collapse ${
            this.state.showMenu ? "in" : ""
          }`}
        >
          <ul className="nav navbar-nav navbar-right">
            <li className="dropdown">
              <Link to="/signup" className=" dropdown-toggle">
                Sign Up
              </Link>
            </li>
            <li className="dropdown">
              <Link to="/signin" className=" dropdown-toggle">
                Sign In
              </Link>
            </li>

            {isVirtualStore && (
              <li className="dropdown">
                <ServiceAreaPopUp open={this.state.showMenu} />
              </li>
            )}

            <li className="dropdown">
              <a href="#">
                <i className="fa fa-phone" style={{ marginRight: "5px" }} />
                {this.props.storePhone
                  ? this.props.storePhone
                  : " +91 81249 68000"}
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
function select(state) {
  return {
    serviceArea: state.cart.serviceArea ? state.cart.serviceArea : null,
    userOnStore:
      state.cart && state.cart.userOnStore ? !!state.cart.userOnStore : null,
  };
}

export default connect(select)(AnonymousHeader);
