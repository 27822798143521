import React from "react";
import { connect } from "react-redux";
import signUpService from "../../services/SignUpService";
import Validation from "../../utils/ValidationUtils";
import Error from "../../widgets/alerts/Error";
import "./UserForm.css";
import Actions from "../../state/actions";
import history from "../../utils/History";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = { key: this.props.match.params.key };
  }

  submit = e => {
    e.preventDefault();
    this.setState({ error: "" });
    var errorMessage = Validation.validateResetPassword(this.state);
    if (errorMessage) {
      this.setState({ error: errorMessage });
      return;
    }
    signUpService.resetPassword(this.state, res => {
      if (res.success) {
        this.props.toastMessage(
          "Done! Password has been reset. Please login to continue.",
          "success",
          3
        );
        history.push("/signin");
      } else {
        var message =
          res.message ||
          "We are sorry! Sign up failed. Please try again later.";
        this.setState({ error: message });
        return;
      }
    });
  };

  render() {
    return (
      <section className="slice ">
        <div className="wp-section">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3">
                <div className="wp-block default user-form">
                  <div
                    className="form-header base"
                    style={{ textAlign: "center" }}
                  >
                    <h2 style={{ fontWeight: 500 }}>Reset Password</h2>
                  </div>
                  <Error
                    message={this.state.error}
                    style={{ margin: "10px" }}
                  />

                  <div className="form-body">
                    <form
                      onSubmit={this.submit}
                      name="phoneOTPForm"
                      id="phoneOTPForm"
                      className="sky-form"
                      noValidate
                    >
                      <fieldset>
                        <section>
                          <div className="form-group">
                            <label className="label">New Password</label>
                            <label className="input">
                              <input
                                type="password"
                                name="newPassword"
                                placeholder=""
                                className="chat-input"
                                value={this.state.newPassword}
                                onChange={e =>
                                  this.setState({ newPassword: e.target.value })
                                }
                              />
                            </label>
                          </div>
                        </section>
                        <section>
                          <div className="form-group">
                            <label className="label">
                              Reenter New Password
                            </label>
                            <label className="input">
                              <input
                                type="password"
                                name="reNewPassword"
                                placeholder=""
                                className="chat-input"
                                value={this.state.reNewPassword}
                                onChange={e =>
                                  this.setState({
                                    reNewPassword: e.target.value
                                  })
                                }
                              />
                            </label>
                          </div>
                        </section>
                        <section style={{ textAlign: "center" }}>
                          <button
                            className="btn btn-base btn-sign-in"
                            style={{ width: "200px" }}
                            type="submit"
                          >
                            <span>Submit</span>
                          </button>
                        </section>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function actions(dispatch) {
  return {
    toastMessage: (message, type, duration) => {
      dispatch(Actions.toastMessage(message, type, duration));
    }
  };
}
export default connect(
  null,
  actions
)(ResetPassword);
