import React from "react";
import { connect } from "react-redux";
import "./Toaster.css";
import Actions from "../../state/actions";

class Toaster extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.message) {
      var duration = nextProps.duration || 3;
      setTimeout(() => {
        nextProps.clearMessage();
      }, duration * 1000);
    }
  }

  close = () => {
    this.props.clearMessage();
  };

  render() {
    var type = this.props.type;
    var message = this.props.message;
    if (!message || message.length === 0) {
      return null;
    }
    var toasterClass = "";
    if (type === "success") {
      toasterClass += "alert-success";
    } else if (type === "info") {
      toasterClass += "alert-info";
    } else if (type === "warning") {
      toasterClass += "alert-warning";
    } else if (type === "error") {
      toasterClass += "alert-danger";
    }

    return (
      <div className="toaster" style={{ zIndex: 9999 }}>
        <p className={"alert " + toasterClass}>
          <a onClick={this.close} className={"toaster-close " + toasterClass}>
            <i className="fa fa-close" />
          </a>
          {this.props.message}
        </p>
      </div>
    );
  }
}

function data(state) {
  var t = state.toaster;
  return {
    message: t.message,
    type: t.type,
    duration: t.duration
  };
}

function actions(dispatch) {
  return {
    clearMessage: () => dispatch(Actions.clearMessage())
  };
}

export default connect(
  data,
  actions
)(Toaster);
