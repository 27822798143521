import React, { Component } from "react";

class Message extends Component {
  render() {
    var ops = this.props.ops;
    var message = this.props.message;
    var right = message.sender.type !== "BS";
    var topClass = right ? "direct-chat-msg right" : "direct-chat-msg";
    var dtClass = right
      ? "direct-chat-timestamp pull-left"
      : "direct-chat-timestamp pull-right";
    var textAlign = right ? "right" : "left";
    var content = message.content;
    if (message.date) var date = new Date(message.date);
    return (
      <div className={topClass}>
        <div className="direct-chat-info clearfix">
          {date && (
            <span className={dtClass} style={{ fontSize: "10px" }}>
              {date.toLocaleString()}
            </span>
          )}
          {ops && right && <span>{message.sender.name}</span>}
        </div>
        <div className="direct-chat-text" style={{ textAlign: textAlign }}>
          {content}
        </div>
      </div>
    );
  }
}

export default Message;
