import React from "react";
import { Link } from "react-router-dom";
import "./HomeBanner.css";
import ChatContainer from "./../chat/ChatContainer";
import responsive from "../../utils/Responsive";

export default function() {
  var mr = responsive.smallScreen() ? "10px" : "20px";

  return (
    <section
      className="bg-custom home"
      id="home"
      style={{
        padding: "40px 0",
        background: "linear-gradient(0deg,#5581da,#374b73)"
      }}
    >
      <div className="home-sm">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="p-0 d-flex flex-column align-items-center justify-content-center">
                <h1 className="h1 font-light text-white w-full main-text text-center">
                  Buy building materials from your favourite reseller at the
                  lowest prices
                </h1>

                <ul
                  className="ls-l text-standard list-check"
                  style={{ marginTop: "20px" }}
                >
                  <li className="step">
                    <div className="d-flex align-items-center">
                      <span style={{ width: 50 }}>
                        <i
                          className="fa fa-link"
                          style={{ fontSize: "20px", marginRight: mr }}
                        />
                      </span>

                      <div>
                        <div style={{ marginRight: mr }}>
                          {/*Enter Store Link*/}
                          Access Reseller's Store Link
                        </div>
                        {/*<div className="d-flex">*/}
                        {/*  <input*/}
                        {/*    style={{ height: "35px", width: "400px" }}*/}
                        {/*    type="text"*/}
                        {/*  />*/}
                        {/*  <button*/}
                        {/*    style={{ height: "35px" }}*/}
                        {/*    className="btn btn-success"*/}
                        {/*  >*/}
                        {/*    GO*/}
                        {/*  </button>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </li>
                  <li className="step">
                    <div className="d-flex align-items-center">
                      <span style={{ width: 50 }}>
                        <i
                          className="fa fa-map-marker"
                          style={{ fontSize: "20px", marginRight: mr }}
                        />
                      </span>
                      <div>Pick your location</div>
                    </div>
                  </li>
                  <li className="step">
                    <div className="d-flex align-items-center">
                      <span style={{ width: 50 }}>
                        <i
                          className="fa fa-shopping-cart"
                          style={{ fontSize: "20px", marginRight: mr }}
                        />
                      </span>
                      <div>Start to Shop</div>
                    </div>
                  </li>
                </ul>
                {/*<div className="main-btn">*/}
                {/*  <a*/}
                {/*    style={{ margin: "20px 0 50px 0" }}*/}
                {/*    className="btn btn-b-white btn-hb"*/}
                {/*  >*/}
                {/*    Sign Up*/}
                {/*  </a>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
