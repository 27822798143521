import ajax from './Ajax'

function signUp(formData, cb) {
    ajax.post('/users/signup', formData)
        .then((responseJson) => {
            cb(responseJson);
        })
}

function resendEmailConfirmation(email, cb) {
    ajax.get('/users/email-confirmation?email=' + email).then((responseJson) => {
        cb(responseJson);
    })
}

function resendPhoneOTP(phone, cb) {
    ajax.get('/users/phone-otp?phone=' + phone).then((responseJson) => {
        cb(responseJson);
    })
}

function confirmEmail(key, cb) {
    ajax.post('/users/confirm/email', {key: key}).then((responseJson) => {
        cb(responseJson);
    })
}

function confirmMobile(phone, otp, cb) {
    ajax.post('/users/confirm/phone', {phone: phone, otp: otp}).then((responseJson) => {
        cb(responseJson);
    })
}

function forgotPassword(email, cb) {
    ajax.get('/users/forgot-password?email=' + email).then((responseJson) => {
        cb(responseJson);
    })
}

function resetPassword(formData, cb) {
    ajax.post('/users/reset-password', formData).then((responseJson) => {
        cb(responseJson);
    })
}

export default {
    signUp,
    confirmEmail,
    confirmMobile,
    resendEmailConfirmation,
    resendPhoneOTP,
    forgotPassword,
    resetPassword
};