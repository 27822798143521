import React, { Component } from "react";
import {isEmpty} from "../../utils/Utils.js";

class ChatInputForm extends Component {
  constructor(props) {
    super(props);
    this.state = { content: this.props.content || "" };
  }

  componentDidMount() {
    this.chatInput.focus();
  }

  submitMessage = e => {
    e.preventDefault();
    var content = this.state.content;
    if (isEmpty(content)) {
      return;
    }
    this.setState({ content: "" });
    var messageObj = null;
    if (this.props.ops) {
      messageObj = {
        content: content.trim(),
        type: "T",
        sender: { type: "BS" },
        date: new Date(),
        recipient: this.props.user._id
      };
    } else {
      messageObj = {
        content: content.trim(),
        type: "T",
        sender: { type: "U" },
        date: new Date()
      };
    }
    this.props.postMessage(messageObj);
  };

  setContent = content => {
    this.setState({ content: content });
  };

  render() {
    return (
      <form onSubmit={this.submitMessage} method="post">
        <div className="input-group">
          <input
            type="text"
            name="message"
            placeholder="E.g. Dalmia cement 50 bags"
            style={{
              width: "100%",
              height: "40px",
              border: "2px solid #ddd",
              borderRight: "0px",
              padding: "8px 7px"
            }}
            value={this.state.content}
            autoComplete="off"
            ref={e => {
              this.chatInput = e;
            }}
            onChange={e => {
              this.setContent(e.target.value);
            }}
            disabled={this.props.connectionOff}
          />
          <span className="input-group-btn">
            <button
              style={{ height: "40px" }}
              type="submit"
              className="btn btn-primary btn-flat"
            >
              Send
            </button>
          </span>
        </div>
      </form>
    );
  }
}

export default ChatInputForm;
