import React from "react";
import ProductAttributes from "../Shop/ProductAttributes";
import ProductImage from "../Shop/ProductImage";
import ProductTitle from "../Shop/ProductTitle";

class QuantityForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
      fromComponent,
      data,
      requirement,
      quantity,
      editQuantity,
      success,
      sku,
      attributesMap,
      message
    } = this.props;
    let {
      brand,
      subtype,
      attributes,
      descriptionAttributes,
      basicUnit,
      notes,
    } = sku;
    let options = [];
    if (data && data.options && data.options.length > 0) {
      options = data.options.sort((a, b) => a.price - b.price);
    }

    return (
      <div>
        <div className="card card-product mt-1" style={{ height: "300px" }}>
          <div className="card-body" style={{ padding: "1rem" }}>
            <ProductTitle
              brand={brand}
              subtype={subtype}
              descriptionAttributes={descriptionAttributes}
              attributes={attributesMap}
            />

            <ProductImage images={sku.images} subtype={sku.subtype} />

            <div className="mt-2">
              <ProductAttributes notes={notes} attributes={attributes} />
            </div>
          </div>
        </div>
        <div className="mt-1">
          {(fromComponent === "VIRTUAL_STORE" ||
            (fromComponent === "VIRTUAL_STORE_CART" && editQuantity)) && (
            <div className="d-flex" style={{ alignItems: "center" }}>
              <div style={{ width: "50%" }} className="mr-2">
                <input
                  name="quantity"
                  type="number"
                  style={{
                    border: "1px solid hsl(0,0%,80%)",
                    borderRadius: "4px",
                    height: "38px",
                    padding: "2px 8px",
                    width: "100%"
                  }}
                  placeholder="Quantity"
                  value={quantity}
                  onChange={this.props.onChangeQuantity}
                />
              </div>
              <div style={{ width: "50%" }}>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-block btn-base-1 btn-circle btn-icon-left"
                    onClick={
                      (fromComponent === "PROJECT_CART" ||
                        fromComponent === "VIRTUAL_STORE_CART") &&
                      editQuantity
                        ? this.props.updateCart
                        : this.props.addToCart
                    }
                    disabled={!success}
                  >
                    <i className="icon ion-android-cart" />
                    {fromComponent === "VIRTUAL_STORE_CART" && editQuantity
                      ? "Update"
                      : " Add to cart"}
                  </button>
                </div>
              </div>
            </div>
          )}
          {fromComponent === "VIRTUAL_STORE_CART" && !editQuantity && (
            <div className="d-flex">
              <span>{requirement.quantity}</span>
              <i
                className="fa fa-pencil-square-o "
                onClick={this.props.setEditQuantity}
              />
            </div>
          )}
          {success && (
            <div className="price-wrapper d-flex mt-2 justify-content-between">
              <div className="price-value strong-600 mr-1">
                {"₹" + data.priceWithTax}
              </div>
              <div className="mr-1 price-value strong-600">
                {"₹" + data.priceWithOutTax + " (excl. tax)"}
              </div>
              <div className="price-value strong-600">
                Total: {data.totalPrice + " (incl. of tax and "}
                {data.isDelivery ? "delivery)" : "excl. of delivery)"}
              </div>
            </div>
          )}
          {message && (
            <span className="mx-1" style={{ color: "red" }}>
              {message}
            </span>
          )}
          {options && options.length !== 0 && (
            <div>
              {success ? (
                <>
                  <hr />
                  <div className="my-1" style={{ color: "#41a1d0" }}>
                    Some of the better alternatives for your requirement,
                  </div>
                </>
              ) : (
                <div className="my-1" style={{ color: "red" }}>
                  Entered quantity is not serviceable. Please enter one of the
                  options given below.
                </div>
              )}

              <div
                style={{
                  border: "1px solid #7e8ba4",
                  padding: 10,
                  borderRadius: 10
                }}
                className="d-flex flex-column mb-1"
              >
                {options.map((option, index) => (
                  <div style={{ fontWeight: 500 }}>
                    <span>{option.quantity}</span>
                    {option.price && (
                      <span>
                        {" at " + "₹ " + option.price + " / " + basicUnit}
                      </span>
                    )}
                    {option.exact && (
                      <span>
                        {quantity +
                          " at ₹ " +
                          option.data.price +
                          " / " +
                          basicUnit}
                      </span>
                    )}
                    {options.length !== index + 1 && <hr />}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default QuantityForm;
