import React from "react";
import {calculateBidValue,getAmountPayable} from "./../../utils/Utils";

class Bid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var bid = this.props.bid;
    var quantity = this.props.quantity;
    if (!bid || !quantity) {
      return null;
    }
    var bidNode = null;
    //var classes = this.props.active ? 'panel panel-default panel-sidebar-1 clickablePanel activeBid' : 'panel panel-default panel-sidebar-1 clickablePanel';
    var classes = this.props.classes
      ? "panel panel-default panel-sidebar-1 " + this.props.classes
      : "panel panel-default panel-sidebar-1";

    if (bid) {
      bidNode = (
        <div className={classes}>
          <div className="panel-heading" style={{ padding: "7px" }}>
            <h2 style={{ fontSize: "14px", fontWeight: "bold" }}>
              Rs. {getAmountPayable(quantity.value, bid)}
              {this.props.confirmed && (
                <span
                  className="label label-success pull-right"
                  style={{ marginRight: "10px", padding: "5px" }}
                >
                  Confirmed
                </span>
              )}
            </h2>
          </div>

          <div className="panel-body" style={{ padding: "7px" }}>
            <div className="row">
              <div className="col-md-6">
                {bid.seller && bid.seller.name && (
                  <div>
                    <p style={{ fontSize: "14px" }}>Seller Details</p>
                    {bid.seller.name && <p>{bid.seller.name}</p>}
                    {bid.seller.email && <p>{bid.seller.email}</p>}
                    {bid.seller.phone && <p>{bid.seller.phone}</p>}
                    {bid.seller.address && <p>{bid.seller.address}</p>}
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <div className="panel-body" style={{ padding: "1px" }}>
                  <table className="table table-cart-subtotal">
                    <tbody>
                      <tr>
                        <th>Unit Price</th>
                        <td className="text-right">
                          <span className="amount">Rs. {bid.unitPrice}</span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          {"Price for " + quantity.value + " " + quantity.unit}
                        </th>
                        <td className="text-right">
                          <span className="amount">
                            Rs.{" "}
                            {calculateBidValue(
                              bid.unitPrice,
                              quantity.value
                            )}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>Shipping and Handling</th>
                        <td className="text-right">
                          Rs. {bid.deliveryCharges}
                        </td>
                      </tr>
                      <tr>
                        <th style={{ fontWeight: "bold" }}>Total</th>
                        <td
                          className="text-right"
                          style={{ fontWeight: "bold" }}
                        >
                          Rs. {getAmountPayable(quantity.value, bid)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return bidNode;
  }
}
export default Bid;

// <tr >
// <th>Service Tax</th>
// <td className="text-right"><span
// className="amount">Rs. {getServiceTax(bid.deliveryCharges)}</span>
// </td>
// </tr>
