import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Env from "./../../../Env";
var serverURL = Env.serverURL;
serverURL += "/banner/";
class BannerImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSelect = (index) => {
    this.setState({ index });
  };

  render() {
    let { bannerImages = [] } = this.props;
    return (
      <div style={{ padding: 10, marginTop: 50 }}>
        {bannerImages.length > 1 ? (
          <React.Fragment>
            <Carousel>
              {bannerImages.map((img, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-50"
                    style={{ height: 500, width: "100%" }}
                    src={serverURL + img}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </React.Fragment>
        ) : (
          <div>
            <img
              className="d-block w-100"
              src={serverURL + bannerImages[0]}
              style={{ height: 500, width: "100%" }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default BannerImages;
