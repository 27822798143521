import React from "react";
import ChatInputForm from "./ChatInputForm";
import ChatAuthenticationContainer from "./ChatAuthenticationContainer";
import ChatConversation from "./ChatConversation";
import validationUtils from "../../utils/ValidationUtils";
import "./ChatContainer.css";
import { connect } from "react-redux";
import {
  setUpSocket,
  setupAllMessagesReceiver,
  processMessage,
  setUpMessageReceiver,
  emitUserInfo,
  emitToken,
  setupNoUserHandler,
  getFirstWelcomeMessage,
  getOtherWelcomeMessages
} from "../../state/actions/message";
import history from "../../utils/History";
import { createChatContact, logIn } from "../../state/actions/login";

class ChatContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showAuth: false };
  }

  componentDidMount() {
    if (!this.props.user.token && !this.props.priceList) {
      this.props.getFirstWelcomeMessage();
      this.props.getOtherWelcomeMessages();
    }
    this.props.setUpSocket();
    this.configureSession();
    this.props.setupNoUserHandler(this.configureSession);
    this.props.setupAllMessagesReceiver();
    this.props.setUpMessageReceiver();
  }

  configureSession = () => {
    var props = this.props;
    if (props.user && props.user._id) {
      this.setLogin(props.user.token);
    } else if (props.contact && props.contact._id) {
      this.setContact(props.contact);
    } else {
      this.setState({
        allowChat: false,
        loggedIn: false,
        userInfo: null,
        token: null
      });
    }
  };

  setContact = contact => {
    this.setState({
      showAuth: false,
      allowChat: true,
      loggedIn: false,
      userInfo: contact
    });
    this.props.emitUserInfo(contact._id);
  };

  setLogin = token => {
    this.setState({
      showAuth: false,
      allowChat: true,
      loggedIn: true,
      token: token
    });
    this.props.emitToken(token);
  };

  postMessage = message => {
    var props = this.props;
    if (
      (props.user && props.user._id) ||
      (props.contact && props.contact._id)
    ) {
      this.props.processMessage(message);
    } else {
      this.setState({ showAuth: true });
      this.lastMessage = message;
    }
  };

  signIn = formData => {
    this.setState({ signInError: "" });
    var errorMessage = validationUtils.validateLoginForm(formData);
    if (errorMessage) {
      this.setState({ signInError: errorMessage });
      return;
    }

    var suCallback = data => {
      this.setLogin(data.token);
      if (this.lastMessage) {
        this.props.processMessage(this.lastMessage);
        this.lastMessage = null;
      }
      history.push("dashboard");
    };

    var errCallback = message => {
      this.setState({ signInError: message });
    };
    this.props.logIn(
      formData.email,
      formData.password,
      errCallback,
      suCallback
    );
  };

  submitContact = formData => {
    this.setState({ contactError: "" });
    //todo validation
    var suCallback = data => {
      this.setContact(data);
      if (this.lastMessage) {
        this.props.processMessage(this.lastMessage);
        this.lastMessage = null;
      }
    };
    var errCallback = message => {
      this.setState({ contactError: message });
    };
    this.props.createChatContact(
      formData.name,
      formData.phone,
      formData.email,
      errCallback,
      suCallback
    );
  };

  render() {
    var chatNode = (
      <div>
        <ChatConversation
          messages={this.props.messages}
          style={this.props.convoStyle}
        />
        <ChatInputForm
          content={this.props.content}
          postMessage={this.postMessage}
        />
      </div>
    );

    if (this.state.showAuth) {
      chatNode = (
        <ChatAuthenticationContainer
          signIn={this.signIn}
          signInError={this.state.signInError}
          contactError={this.state.contactError}
          submitContact={this.submitContact}
        />
      );
    }
    return chatNode;
  }
}

function select(state) {
  return {
    user: state.user,
    contact: state.contact,
    messages: state.messages ? state.messages.messages : []
  };
}

function actions(dispatch) {
  return {
    setUpSocket: () => dispatch(setUpSocket()),
    setupAllMessagesReceiver: () => dispatch(setupAllMessagesReceiver()),
    processMessage: message => dispatch(processMessage(message)),
    setUpMessageReceiver: () => dispatch(setUpMessageReceiver()),
    emitUserInfo: userInfo => dispatch(emitUserInfo(userInfo)),
    emitToken: token => dispatch(emitToken(token)),
    setupNoUserHandler: cb => dispatch(setupNoUserHandler(cb)),
    createChatContact: (name, phone, email, ecb, scb) => {
      dispatch(createChatContact(name, phone, email, ecb, scb));
    },
    logIn: (email, password, ecb, scb) => {
      dispatch(logIn(email, password, ecb, scb));
    },
    getFirstWelcomeMessage: () => {
      dispatch(getFirstWelcomeMessage());
    },
    getOtherWelcomeMessages: () => {
      dispatch(getOtherWelcomeMessages());
    }
  };
}

export default connect(
  select,
  actions
)(ChatContainer);
