import React from "react";

export default function ServiceLocationsView({ serviceAreas }) {
  if (serviceAreas.length === 1) {
    let serviceArea = serviceAreas[0];
    return (
      <div>
        <div className="mb-1">District: {serviceArea.district}</div>
        <div className="mb-1">Zone: {serviceArea.zone}</div>
        <div className="mb-1">
          Localities: {serviceArea.localities.join(", ")}
        </div>
      </div>
    );
  }
  return (
    <div
      style={
        {
          // maxHeight: "calc(100vh)",
          // overflowY: "auto"
        }
      }
    >
      <table className="table table-cart">
        <thead>
          <tr>
            <th>District</th>
            <th>Zone</th>
            <th>Localities</th>
          </tr>
        </thead>
        <tbody>
          {serviceAreas.map(serviceArea => (
            <React.Fragment>
              <tr>
                <td>{serviceArea.district}</td>
                <td>{serviceArea.zone}</td>
                <td>{serviceArea.localities.join(", ")}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
