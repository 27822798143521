import React from "react";

export default ({ images = [], subtype, height, width }) => {
  let image = images[0]
    ? images[0]
    : subtype.toLowerCase().replaceAll(" ", "-") + ".jpg";

  return (
    <div
      style={{
        background: `url(${"/sku-images/" + image})`,
        height,
        width,
        backgroundRepeat: "none",
        backgroundPosition: "center",
        backgroundSize: "cover"
      }}
    />
  );
};
