import React, { Component } from "react";
import Message from "../../widgets/chat/Message";

class ChatConversation extends Component {
  componentDidUpdate() {
    var chatContainer = this.chatContainer;
    if (chatContainer) chatContainer.scrollTop = chatContainer.scrollHeight;
  }

  render() {
    var ops = this.props.ops;
    var messageNodes = this.props.messages.map(function(m, index) {
      return <Message message={m} key={index} ops={ops} />;
    });
    return (
      <div
        className="direct-chat-messages"
        id="chat-messages"
        ref={e => (this.chatContainer = e)}
        style={this.props.style}
      >
        {messageNodes}
      </div>
    );
  }
}

export default ChatConversation;
