import React from "react";

export default function(props) {
  var output = <div></div>;
  if (props.message) {
    output = (
      <div>
        <p className="alert alert-danger alert-message" style={props.style}>
          {props.message}
        </p>
      </div>
    );
  }

  return output;
}
