import React from "react";
import StorageService from "../../../services/StorageService";
import Actions from "./../../../state/actions";
import { connect } from "react-redux";
import VirtualStoreCartView from "./VirtualStoreCartView";
import history from "../../../utils/History";
import Responsive from "../../../utils/Responsive";

class VirtualBuyerCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var mql = Responsive.smallScreenMedia();
    mql.addListener(this.mediaQueryChanged);
    this.setState({ mql: mql, showMobileNav: mql.matches });
  }

  componentWillUnmount() {
    this.state.mql.removeListener(this.mediaQueryChanged);
  }

  mediaQueryChanged = () => {
    this.setState({ showMobileNav: this.state.mql.matches });
  };

  showCart = () => {
    let { code, storeName, cart = [] } = this.props;
    if (cart.length) {
      history.push("/" + code + "/" + storeName + "/cart");
    }
  };

  render() {
    let { cart } = this.props;
    if (this.state.showCart) {
      return (
        <VirtualStoreCartView
          updateCart={this.props.updateCart}
          storeId={this.props.storeId}
        />
      );
    }

    return (
      <div
        className="mb-2"
        style={{
          position: "fixed",
          right: 0,
          top: this.state.showMobileNav ? "126px" : "65px",
          zIndex: 1000,
        }}
      >
        <button
          className="btn btn-dark"
          onClick={this.showCart}
          style={{
            borderRadius: 4,
            cursor: "pointer",
            marginRight: 10,
            border: "2px solid #fff",
            boxShadow: "0 2px 10px rgb(0 0 0 / 12%)",
          }}
        >
          <i className="fa fa-shopping-cart" style={{ fontSize: 22 }} />

          <span style={{ fontSize: 20 }}>
            {cart && cart.length ? cart.length : "0"}
          </span>
        </button>
      </div>
    );
  }
}

function select(state) {
  return {
    cart: state.cart ? state.cart.items : [],
  };
}

function actions(dispatch) {
  return {
    // fetchRequests: () => dispatch(fetchRequests()),
  };
}

export default connect(select, actions)(VirtualBuyerCart);
