import React from "react";

class Success extends React.Component {
  render() {
    var output = <div></div>;
    if (this.props.message) {
      output = (
        <div>
          <p
            className="alert alert-success alert-message"
            style={this.props.style}
          >
            {this.props.message}
          </p>
        </div>
      );
    }
    return output;
  }
}

export default Success;
