import React from "react";
import { connect } from "react-redux";
import { addServiceArea } from "../../../state/actions/cart";
import history from "../../../utils/History";
import ModalN from "../../../widgets/modal/ModalN";
import ServiceAreaFormBuyer from "./ServiceAreaFormBuyer";
import Actions from "../../../state/actions";

class ServiceAreaPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = { serviceArea: {} };
  }

  showAddLocation = () => {
    this.setState({ showAddLocation: true });
  };

  closeModal = () => {
    this.setState({ showAddLocation: false });
  };

  onAddServiceArea = (serviceArea) => {
    let { code, storeName } = this.props;
    if (
      serviceArea &&
      serviceArea.state &&
      serviceArea.district &&
      serviceArea.zone &&
      serviceArea.locality
    ) {
      this.props.addServiceArea(serviceArea);
      this.closeModal();
      history.push("/" + code + "/" + storeName);
    } else {
      this.props.toastMessage("Please enter all the fields", "error", 10);
    }
  };

  render() {
    let { serviceArea, style = {} } = this.props;
    let { showAddLocation } = this.state;

    if (!serviceArea) {
      return (
        <ModalN
          show={!serviceArea}
          close={this.closeModal}
          title="Select Your Location"
          modalHeight={{
            minHeight: "calc(100vh - 700px)",
          }}
        >
          <ServiceAreaFormBuyer
            storeId={this.props.storeId}
            onAddServiceArea={this.onAddServiceArea}
          />
        </ModalN>
      );
    }

    return (
      <React.Fragment>
        <div
          style={{
            fontStyle: "italic",
            textTransform: "capitalize",
            cursor: "pointer",
            padding: 15,
            color: "#f29999",
            fontWeight: 500,
            ...style,
          }}
          onClick={this.showAddLocation}
        >
          <i className="fa fa-map-marker" style={{ marginRight: "5px" }} />
          {serviceArea.locality ? serviceArea.locality.toLowerCase() : ""}
        </div>
        {showAddLocation && (
          <ModalN
            show={showAddLocation}
            close={this.closeModal}
            title="Select Your Location"
            modalHeight={{
              minHeight: "calc(100vh - 700px)",
            }}
          >
            <ServiceAreaFormBuyer
              storeId={this.props.storeId}
              onAddServiceArea={this.onAddServiceArea}
            />
          </ModalN>
        )}
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    serviceArea: state.cart.serviceArea ? state.cart.serviceArea : null,
    storeId: state.cart && state.cart.store ? state.cart.store.storeId : null,
    storeName:
      state.cart && state.cart.store ? state.cart.store.storeName : null,
    code: state.cart && state.cart.store ? state.cart.store.code : null,
  };
}

function actions(dispatch) {
  return {
    addServiceArea: (sa) => dispatch(addServiceArea(sa)),
    toastMessage: (message, type, duration) => {
      dispatch(Actions.toastMessage(message, type, duration));
    },
  };
}
export default connect(select, actions)(ServiceAreaPopUp);
