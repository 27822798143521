/**
 * @flow
 */

'use strict';

//
//export type State = {
//  isLoggedIn: boolean;
//  hasSkippedLogin: boolean;
//  sharedSchedule: ?boolean;
//  id: ?string;
//  name: ?string;
//};

const initialState = {
    isLoggedIn: false,
    _id: null,
    name: null, email: null, phone: null, city: null, type: null,
    token: null
};

function user(state = initialState, action = null) {
    if (action.type === 'LOGGED_IN') {
        let {_id, name, email, phone, city, token,ops, type} = action.data;
        return {
            isLoggedIn: true,
            _id,
            name, email, phone, city, type,
            token, ops
        };
    }

    if (action.type === 'LOGGED_OUT') {
        return initialState;
    }

    if (action.type === 'UPDATED_USER_DATA') {
        var user = Object.assign({}, state);
        user.name = action.data.name;
        user.email = action.data.email;
        user.phone = action.data.phone;
        user.city = action.data.city;
        return user;
    }

    if (action.type === 'RECEIVE_SETTINGS') {
        return Object.assign({}, state, {settings: action.data.settings});
    }
    return state;
}

export default user;
