function isEmpty(string) {
  if (!string || string.length === 0) {
    return true;
  }
  return false;
}

function validateEmail(email) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var valid = re.test(email);
  return valid;
}
function validateEmailPhone(email) {
  //var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var emailRx =
    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  var phoneRx = /^\d{10}$/;
  return emailRx.test(email) || phoneRx.test(email);
}
function validatePhone(phone) {
  var phoneRx = /^\d{10}$/;
  return phoneRx.test(phone);
}

function validateSignUpForm(state) {
  var name = state.name;
  var email = state.email;
  var phone = state.phone;
  var password = state.password;
  var confirmPassword = state.cPassword;

  if (
    isEmpty(name) ||
    isEmpty(phone) ||
    isEmpty(password) ||
    isEmpty(confirmPassword)
  ) {
    return "All fields are required.";
  }
  if (isEmpty(phone) || !validatePhone(phone)) {
    return "Invalid phone";
  }
  if (!isEmpty(email) && !validateEmail(email)) {
    return "Invalid email";
  }
  if (password !== confirmPassword) {
    return "Passwords do not match";
  }
}
function validateNewStoreForm(state) {
  var name = state.name;
  var phone = state.phone;
  var address = state.address;
  var position = state.position;

  if (isEmpty(name) || isEmpty(phone) || isEmpty(address)) {
    return "All fields are required.";
  }
  if (isEmpty(phone) || !validatePhone(phone)) {
    return "Invalid phone";
  }
}

function validateNewVirtualStoreForm(state) {
  var name = state.name;
  var serviceAreas = state.serviceAreas;

  if (isEmpty(name)) {
    return "Enter your store name";
  }
  if (!serviceAreas) {
    return "Enter service location";
  }
}

function validateNewProjectForm(state) {
  var name = state.name;
  var phone = state.phone;
  var address = state.address;
  var serviceArea = state.serviceArea;
  var position = state.position;

  if (
    isEmpty(name) ||
    isEmpty(phone) ||
    isEmpty(serviceArea) ||
    isEmpty(address)
  ) {
    return "All fields are required.";
  }
  if (isEmpty(phone) || !validatePhone(phone)) {
    return "Invalid phone";
  }
}

function validateVirtualBuyerForm(state) {
  var serviceArea = state.serviceArea;

  // if (isEmpty(serviceArea)) {
  //   return "All fields are required.";
  // }

  if (
    !serviceArea ||
    !serviceArea.state ||
    !serviceArea.district ||
    !serviceArea.zone ||
    !serviceArea.locality
  ) {
    return "All fields are required.";
  }
}

function validateShippingAddress(state) {
  var { addressLine1, city, pinCode } = state;

  if (isEmpty(addressLine1) || isEmpty(pinCode)) {
    return "All fields are required.";
  }
}

function validateNewLeadForm(state) {
  var name = state.name;
  var phone = state.phone;
  var serviceEmployee = state.serviceEmployee;
  var followUpType = state.followUpType.value;
  var leadSource = state.leadSource;

  if (
    isEmpty(name) ||
    isEmpty(phone) ||
    isEmpty(serviceEmployee) ||
    isEmpty(followUpType) ||
    isEmpty(leadSource)
  ) {
    return "All fields are required.";
  }
  if (isEmpty(phone) || !validatePhone(phone)) {
    return "Invalid phone";
  }
}

function validateEditLeadForm(validationObject) {
  var name = validationObject.name;
  // var serviceArea = validationObject.serviceArea;
  var status = validationObject.status;
  var comment = validationObject.comment;

  if (
    isEmpty(name) ||
    // isEmpty(serviceArea) ||
    // isEmpty(serviceArea.locality) ||
    isEmpty(status) ||
    isEmpty(comment)
  ) {
    return "All fields are required.";
  }
}

function validateServiceAreaForm(state) {
  var district = state.district;
  var zone = state.zone;
  var localities = state.localities;

  if (
    !district ||
    district.length === 0 ||
    !zone ||
    zone.length === 0 ||
    !localities ||
    localities.length === 0
  ) {
    return "All fields are required.";
  }
}

function validatePricingForm(state) {
  var price = state.price;
  var min = state.min;
  var multiple = state.multiple;

  if (isEmpty(price) || isEmpty(min) || isEmpty(multiple)) {
    return "All fields are required.";
  }
}

function validateCreateOrderForm(state) {
  var productName = state.productName;
  var category = state.category;
  var brand = state.brand;
  var quantity = state.quantity;
  var price = state.price;
  var paymentMethod = state.paymentMethod;
  var deliveryAddress = state.deliveryAddress;

  if (
    isEmpty(productName) ||
    isEmpty(category) ||
    isEmpty(brand) ||
    isEmpty(quantity) ||
    isEmpty(price) ||
    isEmpty(paymentMethod) ||
    isEmpty(deliveryAddress)
  ) {
    return "All fields are required";
  }
}

function validateCreateBidRequestForm(state) {
  var productName = state.productName;
  var productCategory = state.productCategory;
  var productBrand = state.productBrand;
  var quantity = state.quantity;
  var paymentTerms = state.paymentTerms;
  var city = state.city;
  var pinCode = state.pinCode;
  var biddingLastDate = state.biddingLastDate;

  if (
    isEmpty(productName) ||
    isEmpty(productCategory) ||
    isEmpty(productBrand) ||
    isEmpty(paymentTerms) ||
    isEmpty(city) ||
    isEmpty(pinCode) ||
    isEmpty(
      biddingLastDate ||
        !quantity ||
        isEmpty(quantity.value) ||
        isEmpty(quantity.unit)
    )
  ) {
    return "All fields are required";
  }
}

function validateLoginForm(state) {
  var email = state.email;
  var password = state.password;
  if (isEmpty(email) || isEmpty(password)) {
    return "Email and Password are required.";
  }
  if (!validateEmailPhone(email)) {
    return "Invalid Email/Phone.";
  }
}

function validateSettings(state) {
  var name = state.name;
  if (isEmpty(name)) {
    return "Name is required";
  }
}

function validateChangePassword(state) {
  var currentPassword = state.currentPassword;
  var newpassword = state.newPassword;
  var reNewPassword = state.reNewPassword;
  if (
    isEmpty(currentPassword) ||
    isEmpty(newpassword) ||
    isEmpty(reNewPassword)
  ) {
    return "All fields are required";
  }
  if (newpassword != reNewPassword) {
    return "New passwords do not match";
  }
}

function validateResetPassword(state) {
  var newpassword = state.newPassword;
  var reNewPassword = state.reNewPassword;
  if (isEmpty(newpassword) || isEmpty(reNewPassword)) {
    return "All fields are required";
  }
  if (newpassword != reNewPassword) {
    return "New passwords do not match";
  }
}

function validatePhoneForm(phone) {
  if (isEmpty(phone)) {
    return "Phone required";
  }
  if (!isEmpty(phone) && !validatePhone(phone)) {
    return "Invalid phone";
  }
}

function validateEmailForm(email) {
  if (isEmpty(email)) {
    return "Email required";
  }
  if (!isEmpty(email) && !validateEmail(email)) {
    return "Invalid email";
  }
}

function validateConfirmEmail(key) {
  if (isEmpty(key)) {
    return "Key required";
  }
}

function validateConfirmPhone(state) {
  var phone = state.phone;
  var otp = state.otp;
  if (isEmpty(phone) || isEmpty(otp)) {
    return "All fields are  required";
  }
  if (!isEmpty(phone) && !validatePhone(phone)) {
    return "Invalid phone";
  }
}

function validateChatUserInfoForm(state) {
  var name = state.name;
  var phone = state.phone;
  var email = state.email;

  if (isEmpty(name) || isEmpty(phone)) {
    return "Name and Phone are required";
  }
  if (isEmpty(phone) || !validatePhone(phone)) {
    return "Valid Phone is required.";
  }
  if (!isEmpty(email) && !validateEmail(email)) {
    return "Invalid Email";
  }
}
function validateForgotPassword(phone) {
  if (isEmpty(phone)) {
    return "Email/Phone required";
  }
  if (!isEmpty(phone) && !validateEmailPhone(phone)) {
    return "Invalid Email/Phone";
  }
}
export default {
  validateEmailPhone: validateEmailPhone,
  validateSignUpForm: validateSignUpForm,
  validateCreateOrderForm: validateCreateOrderForm,
  validateCreateBidRequestForm: validateCreateBidRequestForm,
  validateLoginForm: validateLoginForm,
  validateSettings: validateSettings,
  validateChangePassword: validateChangePassword,
  validatePhoneForm: validatePhoneForm,
  validateEmailForm: validateEmailForm,
  validateChatUserInfoForm: validateChatUserInfoForm,
  validateResetPassword: validateResetPassword,
  validateConfirmEmail: validateConfirmEmail,
  validateConfirmPhone: validateConfirmPhone,
  validateForgotPassword: validateForgotPassword,
  validateNewStoreForm: validateNewStoreForm,
  validateNewVirtualStoreForm: validateNewVirtualStoreForm,
  validateNewProjectForm: validateNewProjectForm,
  validateNewLeadForm: validateNewLeadForm,
  validateEditLeadForm: validateEditLeadForm,
  validatePricingForm: validatePricingForm,
  validateServiceAreaForm: validateServiceAreaForm,
  validateVirtualBuyerForm: validateVirtualBuyerForm,
  validateShippingAddress: validateShippingAddress,
};
