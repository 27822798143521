import React from "react";
import ordersService from "../../services/OrdersService";
import ConfirmedBid from "./../bids/ConfirmedBid";
import OrderPaymentMethod from "./OrderPaymentMethod";
import OrderStatus from "./OrderStatus";

class OrderSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getOrder();
  }

  getOrder() {
    ordersService.getOrderById(this.props.params.orderId, order => {
      this.setState({ order: order });
    });
  }

  getProduct = product => {
    var label;
    if (
      product.category &&
      (product.brand || product.productType || product.variant)
    ) {
      label = product.category + "/";
    } else if (!product.category) {
      label = "";
    } else {
      label = product.category;
    }
    if (product.brand && (product.productType || product.variant)) {
      label += product.brand + "/";
    } else if (!product.brand) {
      label += "";
    } else {
      label += product.brand;
    }
    if (product.productType && product.variant) {
      label += product.productType + "/";
    } else if (!product.productType) {
      label += "";
    } else {
      label += product.productType;
    }
    if (product.variant) {
      label += product.variant;
    }
    return label;
  };

  render() {
    var order = this.state.order;
    if (!order) {
      return null;
    }

    var request = order.request;
    return (
      <div>
        <h5 className="text-center">Order Summary {"#" + order.number}</h5>

        <div style={{ textAlign: "center" }}>
          <OrderStatus status={order.status} />
        </div>

        <div className="col-md-12">
          <h5>Customer</h5>

          <div className="col-md-offset-1">
            <div>{order.user.name}</div>
            <div>{order.user.phone}</div>
            <div>{order.user.email}</div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <h5>Product</h5>

            <div className="col-md-offset-1">
              {order.rProduct && <p>{this.getProduct(order.rProduct)}</p>}
              {!order.rProduct && order.request.product && (
                <p>
                  {request.product.category +
                    "/" +
                    request.product.brand +
                    "/" +
                    request.product.name}
                </p>
              )}
              <p>
                {order.request.quantity.value +
                  " " +
                  order.request.quantity.unit}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div style={{ marginTop: "20px" }}>
            <h5>Bid</h5>
            <ConfirmedBid request={request} />
          </div>
          <div style={{ marginTop: "20px" }}>
            <h5>Delivery Address</h5>

            <div className="col-md-offset-1">
              {order.deliveryAddress.addressLine1 && (
                <p>{order.deliveryAddress.addressLine1}</p>
              )}
              {order.deliveryAddress.addressLine2 && (
                <p>{order.deliveryAddress.addressLine2}</p>
              )}
              {order.deliveryAddress.addressLine3 && (
                <p>{order.deliveryAddress.addressLine3}</p>
              )}
              {order.deliveryAddress.city && (
                <p>{order.deliveryAddress.city}</p>
              )}
              {order.deliveryAddress.pinCode && (
                <p>{order.deliveryAddress.pinCode}</p>
              )}
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <h5>Payment Method - {order.paymentMethod}</h5>

            <div className="col-md-offset-1">
              <OrderPaymentMethod order={order} refresh={this.getOrder} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderSummary;
