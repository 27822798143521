import React from "react";

class OrderStatus extends React.Component {
  render() {
    var status = this.props.status;
    if (!status) {
      return null;
    }
    var statusValue = null;
    if (status === "NEW") {
      statusValue = "New";
    } else if (status === "PAYMENT_PENDING") {
      statusValue = "Payment Pending";
    } else if (status === "PAID") {
      statusValue = "Paid. Delivery Pending";
    } else if (status === "CANCELLED") {
      statusValue = "Cancelled";
    } else if (status === "DELIVERED") {
      statusValue = "Delivered";
    }
    //else if (status === 'CREDIT_APPROVED') {
    //    statusValue = 'Credit Approved'
    //} else if (status === 'CREDIT_AVAILED') {
    //    statusValue = '` Availed'
    //} else if (status === 'CREDIT_PAID_BACK') {
    //    statusValue = 'Credit Paid Back'
    //}
    else if (status === "PAYMENT_NOTIFIED") {
      statusValue = "Payment Notified";
    } else {
      statusValue = status;
    }

    return (
      <span
        className="label label-info"
        style={{ marginRight: "10px", padding: "5px" }}
      >
        {statusValue}
      </span>
    );
  }
}

export default OrderStatus;
