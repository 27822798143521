import React from "react";
import { connect } from "react-redux";
import LeftNav from "./LeftNav";
import responsive from "../../utils/Responsive";
import UserBanner from "../../widgets/UserBanner.js";
import "./Dashboard.css";
import BuyerMainContent from "./BuyerMainContent";
import { getProductAttributes } from "../../state/actions/product";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showLeftNav: true };
  }

  componentDidMount() {
    this.adjustHeight();
    var mql = responsive.bigScreenMedia();
    mql.addListener(this.mediaQueryChanged);
    this.setState({ mql: mql, showLeftNav: mql.matches });
    this.props.getProductAttributes();
  }

  adjustHeight = () => {
    var height = responsive.getPageHeight();
    this.setState({ contentHeight: height });
  };

  componentWillUnmount() {
    this.state.mql.removeListener(this.mediaQueryChanged);
  }

  mediaQueryChanged = () => {
    this.setState({ showLeftNav: this.state.mql.matches });
  };

  render() {
    var leftNav = null;
    var user = this.props.user;
    var contentHeight = this.state.contentHeight ? this.state.contentHeight : 0;
    if (this.state.showLeftNav) {
      leftNav = (
        <div>
          <UserBanner user={user} />

          <div
            style={{ textAlign: "left", marginTop: "10px", padding: "0 10px" }}
          >
            <LeftNav userType={user.type} />
          </div>
        </div>
      );
    }
    let mainContent = null;
    let smallScreen = responsive.smallScreen();

    mainContent = (
      <BuyerMainContent
        smallScreen={smallScreen}
        leftNav={leftNav}
        contentHeight={contentHeight}
        user={user}
      />
    );

    return (
      <div className="" style={{ paddingTop: "0px" }}>
        {mainContent}
      </div>
    );
  }
}

function data(state) {
  return {
    user: state.user,
  };
}

function actions(dispatch) {
  return {
    getProductAttributes: () => dispatch(getProductAttributes()),
  };
}
export default connect(data, actions)(Dashboard);
