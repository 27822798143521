function switchTab(tab) {
  return {
    type: "SWITCH_TAB",
    tab,
  };
}

function  hydrate() {
  return {
    type: "HYDRATE",
  };
}

export { switchTab, hydrate };
