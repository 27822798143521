/**
 * @flow
 */

'use strict';

function orders(state = {
    isFetching: false, items: [], currentPage: 1, count: 0,
    maxPages: 0, maxVisible: 1, perPage: 5
}, action = null) {
    switch (action.type) {
        case 'REQUEST_ORDERS':
            return Object.assign({}, state, {isFetching: true});
        case 'RECEIVE_ORDERS':
            return Object.assign({}, state, {
                isFetching: false,
                items: [].concat(action.data.orderItems)
            });
        default:
            return state;
    }
}

export default orders;