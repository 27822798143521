import React from "react";
import history from "../../../utils/History";

class VirtualStoreTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  navigate = (navTo, from) => {
    let { storeName, code } = this.props;
    if (navTo === "store" && from != "home") {
      history.push("/" + code + "/" + storeName);
    } else if (navTo === "Cart" && from != "cart") {
      history.push("/" + code + "/" + storeName + "/cart");
    }
  };
  render() {
    let { storeName, pageName, previous, from = null } = this.props;
    return (
      <div
        className={
          from == "checkout" ? "mb-2 col-md-12 mt-2" : "col-md-12 mt-2"
        }
      >
        <h4
          className="text-center mb-0"
          style={{ color: "#333", cursor: "pointer" }}
        >
          {storeName ? (
            <span onClick={() => this.navigate("store", from)}>
              {storeName}
            </span>
          ) : null}
          {previous ? (
            <span onClick={() => this.navigate(previous, from)}>
              {" / " + previous}
            </span>
          ) : null}
          {pageName ? (
            <span onClick={() => this.navigate(pageName)}>
              {" / " + pageName}
            </span>
          ) : null}
        </h4>
      </div>
    );
  }
}

export default VirtualStoreTitle;
