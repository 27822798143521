import React from "react";
import signUpService from "../../services/SignUpService";
import Validation from "../../utils/ValidationUtils";
import Error from "../../widgets/alerts/Error";
import Success from "../../widgets/alerts/Success";
import LinkToModal from "../../widgets/modal/LinkToModal";
import "./UserForm.css";
import ChangePassword from "../settings/ChangePassword";
import ModalN from "../../widgets/modal/ModalN";
import ResendPhoneOTP from "./ResendPhoneOTP";

class PhoneOTPForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: this.props.email };
  }

  submit = e => {
    e.preventDefault();
    this.setState({ error: "" });
    var errorMessage = Validation.validateConfirmPhone(this.state);
    if (errorMessage) {
      this.setState({ error: errorMessage });
      return;
    }
    signUpService.confirmMobile(this.state.phone, this.state.otp, res => {
      if (res.success) {
        this.setState({
          success: (
            <span>
              Phone is confirmed. Please{" "}
              <a href="/signin" className="btn-link">
                login
              </a>{" "}
              to continue
            </span>
          )
        });
      } else {
        var message =
          res.message ||
          "We are sorry! Sign up failed. Please try again later.";
        this.setState({ error: message });
      }
    });
  };

  showResendOTPModal = () => {
    this.setState({ resendOTPModal: true });
  };

  closeResendOTPModal = () => {
    this.setState({ resendOTPModal: false });
  };

  render() {
    return (
      <section className="slice ">
        <div className="wp-section">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-md-offset-3">
                <div className="wp-block default user-form">
                  <div
                    className="form-header base"
                    style={{ textAlign: "center" }}
                  >
                    <h2 style={{ fontWeight: 500 }}>Confirm Phone</h2>
                  </div>
                  <Error
                    message={this.state.error}
                    style={{ margin: "10px" }}
                  />
                  <Success
                    message={this.state.success}
                    style={{ margin: "10px" }}
                  />

                  <div className="form-body">
                    <form
                      onSubmit={this.submit}
                      name="phoneOTPForm"
                      id="phoneOTPForm"
                      className="sky-form"
                      noValidate
                    >
                      <fieldset>
                        <section>
                          <div className="form-group">
                            <label className="label">Phone</label>
                            <label className="input">
                              <input
                                type="text"
                                name="phone"
                                value={this.state.phone}
                                onChange={e =>
                                  this.setState({ phone: e.target.value })
                                }
                              />
                            </label>
                          </div>
                        </section>
                        <section>
                          <div className="form-group">
                            <label className="label">OTP</label>
                            <label className="input">
                              <input
                                type="text"
                                name="otp"
                                value={this.state.otp}
                                onChange={e =>
                                  this.setState({ otp: e.target.value })
                                }
                              />
                            </label>
                          </div>
                        </section>
                        <section style={{ textAlign: "center" }}>
                          <button
                            className="btn btn-base btn-sign-in"
                            style={{ width: "200px" }}
                            type="submit"
                          >
                            <span>Confirm</span>
                          </button>
                        </section>
                      </fieldset>
                    </form>
                  </div>
                  <div
                    className="form-footer base"
                    style={{ textAlign: "center" }}
                  >
                    <p>
                      <span style={{ color: "#444" }}>
                        Lost your OTP? Click{" "}
                        <a
                          onClick={this.showResendOTPModal}
                          className="btn-link"
                          style={{ color: "#2a6496" }}
                        >
                          here
                        </a>{" "}
                        to recover.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalN
          title="Resend Phone OTP"
          show={this.state.resendOTPModal}
          close={this.closeResendOTPModal}
        >
          <ResendPhoneOTP
            phone={this.state.phone}
            onComplete={this.closeResendOTPModal}
          />
        </ModalN>
      </section>
    );
  }
}

export default PhoneOTPForm;
