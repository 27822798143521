import React from "react";
import "./UserForm.css";
import SignUp from "./SignUp";

class SignUpView extends React.Component {
  render() {
    return (
      <section className="slice ">
        <div className="wp-section">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-md-offset-3">
                <SignUp {...this.props} />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default SignUpView;
