function smallScreenMedia() {
  var mql = window.matchMedia(`(max-width: 991px)`);
  return mql;
}

function bigScreenMedia() {
  var mql = window.matchMedia(`(min-width: 992px)`);
  return mql;
}

function smallScreen() {
  return smallScreenMedia().matches;
}

function getPageHeight() {
  let el = document.getElementById("divHeaderWrapper");
  if (el) {
    let headHeight = el.offsetHeight + 20;
    return window.screen.height - headHeight;
  }
  return window.screen.height;
}

export default {
  smallScreenMedia: smallScreenMedia,
  bigScreenMedia: bigScreenMedia,
  smallScreen: smallScreen,
  getPageHeight: getPageHeight
};
