import React from "react";
import { connect } from "react-redux";
import VirtualBuyerService from "../../../services/VirtualBuyerService";
import history from "../../../utils/History";
import ModalN from "../../../widgets/modal/ModalN";
import PaymentMethod from "./PaymentMethod";
import ShippingAddressesForm from "./ShippingAddressesForm";
import ShippingAddressesView from "./ShippingAddressesView";
import Summary from "./Summary";
import StorageService from "../../../services/StorageService";
import { clearCart, setUserOnStore } from "../../../state/actions/cart";
import VirtualStoreTitle from "./VirtualStoreTitle";
import { toastMessage } from "../../../state/actions/toaster";
import EmptyCart from "./EmptyCart";

class VirtualStoreCheckOut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.props.setUserOnStore(true);
    this.getShippingAddresses();
    this.addStoreId();
  };

  componentWillUnmount = () => {
    this.props.setUserOnStore(false);
  };

  getShippingAddresses = () => {
    let serviceArea = StorageService.getFromLocalStorage("serviceArea");
    VirtualBuyerService.getShippingAddresses(serviceArea)
      .then((response) => {
        if (response.success) {
          let shippingAddresses = response.data;
          this.setState({ shippingAddresses });
        }
      })
      .catch((e) => {});
  };

  addStoreId = () => {
    let { storeId } = this.props.match.params;
    VirtualBuyerService.addStoreId(storeId);
  };

  showAddShippingAddress = () => {
    this.setState({ showAddShippingAddress: true });
  };

  closeAddShippingAddress = () => {
    this.setState({ showAddShippingAddress: false });
    this.getShippingAddresses();
  };

  editShippingAddress = (index, id) => {
    this.setState({
      shippingAddressToEditIndex: index,
      shippingAddressToEditId: id,
    });
  };

  closeEditShippingAddress = () => {
    this.setState({
      shippingAddressToEditIndex: null,
      shippingAddressToEditId: null,
    });
    this.getShippingAddresses();
  };

  setShippingAddress = (id) => {
    this.setState({ selectedShippingAddressId: id });
  };

  returnToShop = () => {
    let { code, storeName } = this.props.match.params;
    history.push("/" + code + "/" + storeName);
  };

  completeOrder = () => {
    let { cartItems, serviceArea, match, storeId } = this.props;
    let { code, storeName } = match.params;
    let { selectedShippingAddressId } = this.state;

    if (!selectedShippingAddressId) {
      this.props.toastMessage("Please select shipping address", "error", 5);
      return;
    }

    console.log("Completing order");
    VirtualBuyerService.createOrdersVirtualBuyer({
      storeId,
      serviceArea,
      addressId: selectedShippingAddressId,
      orderReqs: cartItems,
    })
      .then((response) => {
        this.props.toastMessage("Order(s) have been created.", "success", 5);
        this.props.clearCart();
        history.push("/" + code + "/" + storeName + "/order-placed");
      })
      .catch((e) => {
        this.props.toastMessage(
          "Sorry! we are not able to place your order. Please try again later.",
          "error",
          7
        );
      });
  };

  returnToCart = () => {
    let { code, storeName } = this.props.match.params;
    history.push("/" + code + "/" + storeName + "/cart");
  };

  render() {
    let {
      shippingAddresses,
      showAddShippingAddress,
      shippingAddressToEditId,
      selectedShippingAddressId,
    } = this.state;

    let { code, storeName } = this.props.match.params;
    let { cartItems, storeId } = this.props;
    if (!cartItems || cartItems.length === 0) {
      return <EmptyCart code={code} storeName={storeName} />;
    }

    return (
      <div>
        <VirtualStoreTitle
          previous={"Cart"}
          pageName={"Checkout"}
          storeName={storeName}
          code={code}
          from="checkout"
        />
        <div className="container mt-2">
          <div className="row">
            <div className="col-lg-6">
              <div className="card mt-4">
                <div className="card-body">
                  <h3 className="heading heading-3 strong-400 mb-0">
                    Shipping information
                  </h3>
                  {(!shippingAddresses || shippingAddresses.length === 0) && (
                    <React.Fragment>
                      <p className="c-gray-light mt-1 pl-4">
                        There are no previous shipping address.
                      </p>
                      <a
                        onClick={this.showAddShippingAddress}
                        className="btn btn-sm btn-link"
                      >
                        <i className="fa fa-plus" />
                        Add new
                      </a>
                    </React.Fragment>
                  )}
                  {shippingAddresses && shippingAddresses.length >= 1 && (
                    <div>
                      <ShippingAddressesView
                        shippingAddresses={shippingAddresses}
                        editShippingAddress={this.editShippingAddress}
                        setShippingAddress={this.setShippingAddress}
                        selectedShippingAddressId={selectedShippingAddressId}
                      />
                      <a
                        onClick={this.showAddShippingAddress}
                        className="btn btn-sm btn-link"
                      >
                        <i className="fa fa-plus" />
                        Add new
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-2">
                <PaymentMethod />
              </div>
              <div style={{ width: "100%", marginTop: "5px" }}>
                <a onClick={this.returnToCart} className="link link--style-3">
                  <i className="fa fa-angle-left" aria-hidden="true" /> Back
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <Summary
                code={code}
                storeName={storeName}
                cartItems={this.props.cartItems}
              />
              <div className="d-flex justify-content-end mt-2">
                <div>
                  <button
                    className="btn btn-lg btn-base-1"
                    onClick={this.completeOrder}
                  >
                    Complete order
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showAddShippingAddress && (
          <ModalN
            title="New Shipping Address"
            show={showAddShippingAddress}
            close={this.closeAddShippingAddress}
          >
            <ShippingAddressesForm
              closeAddShippingAddress={this.closeAddShippingAddress}
              serviceArea={this.props.serviceArea}
            />
          </ModalN>
        )}

        {shippingAddressToEditId && (
          <ModalN
            title="Edit Shipping Address"
            show={shippingAddressToEditId}
            close={this.closeEditShippingAddress}
          >
            <ShippingAddressesForm
              shippingAddressId={shippingAddressToEditId}
              edit={true}
              closeEditShippingAddress={this.closeEditShippingAddress}
              shippingAddress={JSON.parse(
                JSON.stringify(
                  shippingAddresses[this.state.shippingAddressToEditIndex]
                )
              )}
            />
          </ModalN>
        )}
      </div>
    );
  }
}

function actions(dispatch) {
  return {
    toastMessage: (message, type, duration) =>
      dispatch(toastMessage(message, type, duration)),
    clearCart: () => dispatch(clearCart()),
    setUserOnStore: (userIn) => dispatch(setUserOnStore(userIn)),
  };
}

function data(state) {
  return {
    cartItems: state.cart ? state.cart.items : [],
    serviceArea: state.cart ? state.cart.serviceArea : [],
    storeId: state.cart && state.cart.store ? state.cart.store.storeId : null,
  };
}

export default connect(data, actions)(VirtualStoreCheckOut);
