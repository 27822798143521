import React from "react";

class SettingsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.user || {};
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps.user);
  }

  submit = e => {
    e.preventDefault();
    var state = this.state;
    //todo validate state and if validation passes call the onsubmit function from props
    this.props.onSubmit(state);
  };

  render() {
    return (
      <form
        name="settingsForm"
        id="settingsForm"
        className="sky-form settings-form"
        onSubmit={this.submit}
        noValidate
      >
        <fieldset>
          <section className="col-md-6 col-md-offset-3">
            <div className="form-group">
              <label className="label">Name</label>
              <label className="input">
                <input
                  type="text"
                  name="name"
                  placeholder=""
                  className="chat-input"
                  value={this.state.name}
                  onChange={e => this.setState({ name: e.target.value })}
                />
              </label>
            </div>
          </section>
          <section className="col-md-6 col-md-offset-3">
            <div className="form-group">
              <label className="label">City</label>
              <label className="input">
                <input
                  type="text"
                  name="city"
                  placeholder=""
                  className="chat-input"
                  value={this.state.city}
                  onChange={e => this.setState({ city: e.target.value })}
                />
              </label>
            </div>
          </section>
          <section className="col-md-6 col-md-offset-3">
            <div className="form-group">
              <label className="label">Address</label>
              <label className="input">
                <input
                  type="text"
                  name="address"
                  placeholder=""
                  className="chat-input"
                  value={this.state.address}
                  onChange={e => this.setState({ address: e.target.value })}
                />
              </label>
            </div>
          </section>
          <section className="col-md-6 col-md-offset-3">
            <button
              className="btn btn-base btn-sign-in pull-right"
              type="submit"
            >
              <span>Submit</span>
            </button>
          </section>
        </fieldset>
      </form>
    );
  }
}

export default SettingsForm;
