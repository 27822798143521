import React from "react";
import ChatContainer from "./../chat/ChatContainer";
import responsive from "../../utils/Responsive";

class MobileChatView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({ contentHt: responsive.getPageHeight() });
  }

  render() {
    return (
      <div>
        <h5 className="dashboard-title">Chat</h5>
        <ChatContainer
          convoStyle={{
            padding: "10px",
            overflowY: "auto",
            height: this.state.contentHt - 185,
            marginBottom: "5px"
          }}
        />
      </div>
    );
  }
}
export default MobileChatView;
