import React, {Component} from 'react'
import Validation from '../../utils/ValidationUtils'
import Error from '../../widgets/alerts/Error'

class ChatUserInfoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    submitContact = (e)=> {
        e.preventDefault();
        this.setState({error: ''});
        var errorMessage = Validation.validateChatUserInfoForm(this.state);
        if (errorMessage) {
            this.setState({error: errorMessage});
            return;
        }
        this.props.onSubmit(this.state);
    };

    render() {
        return (
            <div>
                <Error message={this.state.error} style={{margin:'10px'}}/>

                <form name="signinForm" id="basicInfoForm" className="sky-form" onSubmit={this.submitContact}
                      noValidate>
                    <fieldset>
                        <section>
                            <div className="form-group">
                                <label className="input">
                                    <input type="text" name="name" placeholder="Name" className="chat-input"
                                           value={this.state.name}
                                           onChange={(e)=>{this.setState({name:e.target.value})}}/>
                                </label>
                            </div>
                        </section>
                        <section>
                            <div className="form-group">
                                <label className="input">
                                    <input type="text" name="phone" placeholder="Phone" className="chat-input"
                                           value={this.state.phone}
                                           onChange={(e)=>{this.setState({phone:e.target.value})}}/>
                                </label>
                            </div>
                        </section>
                        <section>
                            <div className="form-group">
                                <label className="input">
                                    <input type="text" name="email" placeholder="Email" className="chat-input"
                                           value={this.state.email}
                                           onChange={(e)=>{this.setState({email:e.target.value})}}/>
                                </label>
                            </div>
                        </section>
                        <section>
                            <button
                                className="btn btn-base btn-sign-in pull-right signin btn-xs"
                                type="submit">
                                <span>Submit</span>
                            </button>
                        </section>
                    </fieldset>
                </form>
            </div>
        )
    }
}

export default ChatUserInfoForm;