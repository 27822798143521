import React from "react";
import signUpService from "../../services/SignUpService";
import Validation from "../../utils/ValidationUtils";
import Error from "../../widgets/alerts/Error";
import "./UserForm.css";
import history from "../../utils/History";
import SignUpConfirmation from "./SignUpConfirmation";

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    props.seller ? (this.state = { type: "B" }) : (this.state = { type: "C" });
  }

  handleSignUp = (e) => {
    e.preventDefault();
    this.setState({ error: "" });
    var errorMessage = Validation.validateSignUpForm(this.state);
    if (errorMessage) {
      this.setState({ error: errorMessage });
      return;
    }
    signUpService.signUp(this.state, (res) => {
      if (res.success) {
        var type =
          this.state.email && this.state.phone
            ? "b"
            : this.state.email
            ? "e"
            : "p";
        // if (this.props.popUp) {
        //   this.setState({ showConfirmation: true, signUpType: type });
        // } else {
        history.push("/signup/confirmation/" + type);
        // }
      } else {
        var message =
          res.message ||
          "We are sorry! Sign up failed. Please try again later.";
        this.setState({ error: message });
      }
    });
  };

  render() {
    if (this.state.showConfirmation) {
      return <SignUpConfirmation type={this.state.signUpType} />;
    }
    return (
      <div className="wp-block default user-form">
        <div className="form-header base" style={{ textAlign: "center" }}>
          <h2 style={{ fontWeight: 500 }}>Sign Up</h2>
        </div>
        <Error message={this.state.error} style={{ margin: "10px" }} />

        <div className="form-body">
          <form
            onSubmit={this.handleSignUp}
            name="signupForm"
            id="signupForm"
            className="sky-form"
            noValidate
          >
            <fieldset>
              <section>
                <div className="form-group">
                  <label className="label">Name</label>
                  <label className="input">
                    <input
                      type="name"
                      name="name"
                      value={this.state.name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                    />
                  </label>
                </div>
              </section>
              <section>
                <div className="form-group">
                  <label className="label">Email (Optional)</label>
                  <label className="input">
                    <input
                      type="text"
                      name="email"
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                  </label>
                </div>
              </section>
              <section>
                <div className="form-group">
                  <label className="label">Mobile</label>
                  <label className="input">
                    <input
                      type="text"
                      name="phone"
                      value={this.state.phone}
                      onChange={(e) => this.setState({ phone: e.target.value })}
                    />
                  </label>
                </div>
              </section>
              {this.props.seller && (
                <section>
                  <div className="form-group">
                    <label className="label">GST number (if availabale)</label>
                    <label className="input">
                      <input
                        type="text"
                        name="gst-number"
                        value={this.state.gstNumber}
                        onChange={(e) =>
                          this.setState({ gstNumber: e.target.value })
                        }
                      />
                    </label>
                  </div>
                </section>
              )}
              <section>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="label">Password</label>
                      <label className="input">
                        <input
                          type="password"
                          name="password"
                          value={this.state.password}
                          onChange={(e) =>
                            this.setState({
                              password: e.target.value,
                            })
                          }
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="label">Re-enter Password</label>
                      <label className="input">
                        <input
                          type="password"
                          name="repassword"
                          value={this.state.repassword}
                          onChange={(e) =>
                            this.setState({
                              cPassword: e.target.value,
                            })
                          }
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <button
                  className="btn btn-base btn-sign-in pull-right"
                  type="submit"
                >
                  <span>Go</span>
                </button>
              </section>
            </fieldset>
          </form>
        </div>
        <div className="form-footer base" style={{ color: "#444" }}>
          <p style={{ color: "#444" }}>
            Already signed up? <a style={{ color: "#444" }}>Login here.</a>
          </p>
        </div>
      </div>
    );
  }
}

export default SignUp;
