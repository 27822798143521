import React from "react";
import { connect } from "react-redux";
import Actions from "../../state/actions";
import "./UserForm.css";
import SignIn from "./SignIn";

class SignInView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ marginTop: "60px" }}>
        <section className="slice ">
          <div className="wp-section">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-5">
                  <SignIn {...this.props} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const actions = (dispatch, ownProps) => {
  return {
    logIn: (email, password, isOps, ecb, scb) => {
      dispatch(Actions.logIn(email, password, isOps, ecb, scb));
    },
  };
};

export default connect(null, actions)(SignInView);
