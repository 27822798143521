/**
 * @flow
 */

"use strict";
//
import * as loginActions from "./login";
import * as signupActions from "./signup";
import * as navigationActions from "./navigation";
import * as requestActions from "./request";
import * as orderActions from "./order";
import * as messageActions from "./message";
import * as settingsActions from "./settings";
import * as toasterActions from "./toaster";
import * as modalActions from "./modal";
import * as productActions from "./product";
//

export default {
    ...loginActions,
    ...signupActions,
    ...navigationActions,
    ...requestActions,
    ...orderActions,
    ...messageActions,
    ...settingsActions,
    ...toasterActions,
    ...modalActions,
    ...productActions
};
