/**
 * @flow
 */

"use strict";

import ajax from "./../../services/Ajax";
import storageService from "./../../services/StorageService";

// function logIn(email, password, ecb, scb) {
//   return dispatch => {
//     ajax
//       .post("/users/authenticate", {
//         email: email,
//         password: password
//       })
//       .then(responseJson => {
//         onLogin(responseJson, dispatch, ecb, scb);
//       });
//   };
// }

function logIn(email, password, isOps, ecb, scb) {
  return dispatch => {
    ajax
      .post(isOps ? "/ops-users/authenticate" : "/users/authenticate", {
        email: email,
        password: password
      })
      .then(responseJson => {
        onLogin(responseJson, dispatch, ecb, scb);
      });
  };
}

// function opsLogin(email, password, ecb, scb) {
//   return dispatch => {
//     ajax
//       .post("/ops-users/authenticate", {
//         email: email,
//         password: password
//       })
//       .then(responseJson => {
//         onLogin(responseJson, dispatch, ecb, scb);
//       });
//   };
// }

function onLogin(response, dispatch, ecb, scb) {
  if (response.success) {
    var user = response.data.user;
    var token = response.data.token;
    //todo implement in app
    var lUser = createLoggedInUserState(user, token);
    storageService.storeLoginUser(lUser);
    dispatch({
      type: "LOGGED_IN",
      data: lUser
    });
    scb(response.data);
  } else {
    ecb(response.message);
  }
}

function createLoggedInUserState(user, token) {
  return {
    _id: user._id,
    name: user.name,
    token: token,
    email: user.email,
    phone: user.phone,
    city: user.city,
    type: user.type
  };
}

function createLoggedInOpsUserState(user, token) {
  return {
    _id: user._id,
    name: user.name,
    token: token,
    email: user.email,
    phone: user.phone,
    city: user.city,
    ops: true
  };
}

function pushUserToState(user) {
  return dispatch => {
    dispatch({
      type: "LOGGED_IN",
      data: user
    });
  };
}

function pushContactToState(contact) {
  return dispatch => {
    dispatch({
      type: "CONTACT_SUBMITTED",
      data: contact
    });
  };
}

function logOut(callback) {
  storageService.clearStorage();
  callback();
  return dispatch => {
    return dispatch({
      type: "LOGGED_OUT"
    });
  };
}

function createChatContact(name, phone, email, ecb, scb) {
  return dispatch => {
    ajax
      .post("/chat-users", {
        name: name,
        phone: phone,
        email: email
      })
      .then(responseJson => {
        onCreateContact(responseJson, dispatch, ecb, scb);
      });
  };
}

function onCreateContact(response, dispatch, ecb, scb) {
  if (response.success) {
    var contact = response.data;
    storageService.storeContact(contact);
    dispatch({
      type: "CONTACT_SUBMITTED",
      data: contact
    });
    scb(contact);
  } else {
    ecb(response.message);
  }
}

export {
  logIn,
  logOut,
  pushUserToState,
  createChatContact,
  pushContactToState
};
