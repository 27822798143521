import React from "react";
import MobileLeftNavMain from "./MobileLeftNavMain";

class MobileLeftNav extends React.Component {
  render() {
    return (
      <MobileLeftNavMain
        onClick={this.props.onClick}
        userType={this.props.user.type}
      />
    );
  }
}

export default MobileLeftNav;
