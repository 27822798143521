import React, { Component } from "react";

class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  signIn = e => {
    e.preventDefault();
    var state = this.state;
    //todo validate state and if validation passes call the onsubmit function from props
    this.props.onSubmit(state);
  };

  render() {
    var btnClass =
      "btn btn-base btn-sign-in pull-right signin " + this.props.btnSize;

    return (
      <form
        name="signinForm"
        id="signinForm"
        className="sky-form"
        onSubmit={this.signIn}
        noValidate
      >
        <fieldset style={{ padding: "10px 0" }}>
          <section>
            <div className="form-group">
              <label className="input">
                <input
                  type="text"
                  name="email"
                  placeholder="Email/Phone"
                  className="chat-input"
                  value={this.state.email}
                  onChange={e => this.setState({ email: e.target.value })}
                />
              </label>
            </div>
          </section>
          <section>
            <div className="form-group">
              <label className="input">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  className="chat-input"
                  value={this.state.password}
                  onChange={e => this.setState({ password: e.target.value })}
                />
              </label>
            </div>
          </section>
          <section>
            <button className={btnClass} type="submit">
              <span>Sign In</span>
            </button>
          </section>
        </fieldset>
      </form>
    );
  }
}

export default SignInForm;
