import React from "react";
import { connect } from "react-redux";
import signUpService from "../../services/SignUpService";
import Validation from "../../utils/ValidationUtils";
import Error from "../../widgets/alerts/Error";
import "./UserForm.css";
import Actions from "../../state/actions";

class ResendPhoneOTP extends React.Component {
  constructor(props) {
    super(props);
    this.state = { phone: this.props.phone };
  }

  submit = e => {
    e.preventDefault();
    this.setState({ error: "" });
    var errorMessage = Validation.validatePhoneForm(this.state.phone);
    if (errorMessage) {
      this.setState({ error: errorMessage });
      return;
    }
    signUpService.resendPhoneOTP(this.state.phone, res => {
      if (res.success) {
        this.props.onComplete();
        this.props.toastMessage("Done! We have sent you an OTP.", "success", 3);
      } else {
        var message =
          res.message ||
          "We are sorry! Resending phone OTP failed. Please try again later.";
        this.setState({ error: message });
      }
    });
  };

  render() {
    return (
      <div className="wp-block default user-form">
        <Error message={this.state.error} style={{ margin: "10px" }} />

        <div className="form-body">
          <form
            onSubmit={this.submit}
            name="resendPhoneOTPForm"
            id="resendPhoneOTPForm"
            className="sky-form"
            novalidate
          >
            <fieldset style={{ textAlign: "center" }}>
              <section className="col-md-6 col-md-offset-3">
                <div className="form-group">
                  <label className="label">Phone</label>
                  <label className="input">
                    <input
                      type="text"
                      name="phone"
                      value={this.state.phone}
                      onChange={e => this.setState({ phone: e.target.value })}
                    />
                  </label>
                </div>
              </section>
              <section className="col-md-6 col-md-offset-3">
                <div className="form-group">
                  <label className="input">
                    <button className="btn btn-base btn-sign-in" type="submit">
                      <span>Send Phone OTP</span>
                    </button>
                  </label>
                </div>
              </section>
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}

function actions(dispatch) {
  return {
    toastMessage: (message, type, duration) => {
      dispatch(Actions.toastMessage(message, type, duration));
    }
  };
}
export default connect(
  null,
  actions
)(ResendPhoneOTP);
