/**
 * @flow
 */

"use strict";
import ajax from "./../../services/Ajax";

function requestOrders() {
  return {
    type: "REQUEST_ORDERS"
  };
}

function receiveOrders(orders) {
  return {
    type: "RECEIVE_ORDERS",
    data: { orderItems: orders },
    isFetching: false
  };
}

function shouldFetchOrders(state) {
  const orders = state.orders;
  if (!orders) {
    return true;
  } else {
    return !orders.isFetching;
  }
}

function fetchOrders() {
  return (dispatch, getState) => {
    if (shouldFetchOrders(getState())) {
      dispatch(requestOrders());
      ajax.get("/orders").then(json => {
        if (json.success) {
          dispatch(receiveOrders(json.data));
        } else {
          dispatch(receiveOrders([]));
        }
      });
    }
  };
}

export { fetchOrders };
