import React from "react";
import Env from "../../Env";

export default function({ subtype, brands }) {
  return (
    <div className="col-md-4 mb-2">
      <div
        className="p-2"
        style={{
          // borderBottom: "3px solid #2b90d9"
          border: "1px solid rgb(210 214 222)",
          backgroundColor: "#fff",
          height: "239px"
        }}
      >
        <div className="d-flex flex-column align-items-center">
          <img
            className="pc-icon"
            height={100}
            src={
              "/sku-images/" +
              subtype.toLowerCase().replaceAll(" ", "-") +
              ".jpg"
            }
            alt=""
          />
          <div className="mt-1">
            <span style={{ textTransform: "capitalize", fontSize: "16px" }}>
              {subtype}
            </span>
          </div>
        </div>

        <div className="caption-bottom" style={{ margin: "10px 10px 10px" }}>
          <div className="d-flex flex-wrap justify-content-center">
            {brands.slice(0, 5).map(brand => (
              <div className="m-1">
                <a className="btn-link text-capitalize">{brand}</a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

// <div className="col-md-4">
//     <div className="procurement">
//         <div className="exp">
//             <img className="pc-icon"
//                  src="../images/prv/icons/Potholders.png" alt=""/>
//
//             <div>
//                                         <span style={{
//                                             textTransform: 'capitalize', fontSize: '16px'
//                                         }}>Kitchen Accessories</span>
//
//                 <div className="caption-bottom"
//                      style={{
//                          margin: '20px 10px 10px', wordSpacing: '20px'
//                      }}>
//                                             <span className="title c-white">
//                                                 <a className="link caption">Cement</a>
//                                                 </span>
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>
// <div className="col-md-4">
//     <div className="procurement">
//     <div className="exp">
//     <img className="pc-icon"
// src="../images/prv/icons/Listings.png" alt=""/>
//
//     <div>
//     <span style={{textTransform: 'capitalize', fontSize: '16px'}}>Others</span>
//
// <div className="caption-bottom"
// style={{margin: '20px 10px 10px', wordSpacing: '20px'}}>
// <span className="title c-white">
//     <a className="link caption">Cement</a>
//     <a className="link caption">steel</a>
//     </span>
//     </div>
//     </div>
//     </div>
//     </div>
//     </div>
