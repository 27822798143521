import React from "react";
import Category from "./Category";
import Ajax from "../../services/Ajax";

class CategoriesContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { subtypes: {} };
  }

  componentDidMount() {
    Ajax.get("/api/v2/products/subtypes-brands").then(response => {
      this.setState({ subtypes: response.success ? response.data : {} });
    });
  }
  render() {
    let { subtypes } = this.state;

    return (
      <div className="container">
        <div className="row" style={{ backgroundColor: "#fafafa" }}>
          <div className="col-md-12" id="product-categories">
            <div className="section-title-wr style-1">
              <div style={{ marginTop: "40px" }}>
                <h3 className="section-title left">
                  <span>What Can You Buy?</span>
                </h3>
              </div>
            </div>
            <section className="">
              <div className="wp-section">
                <div className="container">
                  <div className="row">
                    <div>
                      <label>
                        From us? In fact everything you need to construct and
                        maintain a building. Here are a few categories for your
                        reference.
                      </label>
                    </div>
                    <div className="row mt-2">
                      {Object.keys(subtypes).map(s => (
                        <Category subtype={s} brands={subtypes[s]} />
                      ))}
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default CategoriesContainer;
