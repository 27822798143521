import React from "react";
import VirtualBuyerService from "../../../services/VirtualBuyerService";
import history from "../../../utils/History";
import ModalN from "../../../widgets/modal/ModalN";
import ServiceLocationsView from "./ServiceLocationsView";
import Actions from "../../../state/actions";
import { connect } from "react-redux";

class VirtualStoresView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    VirtualBuyerService.getVirtualStores().then((response) => {
      if (response.success) {
        this.setState({ virtualStores: response.data });
      }
    });
  };

  openVirtualStore = (code, storeName, status) => {
    if (status == "INACTIVE") {
      this.props.toastMessage(
        "Sorry! Looks like the link you have followed is not valid anymore.",
        "error",
        10
      );
    } else {
      history.push("/" + code + "/" + storeName);
    }
  };

  showServiceLocations = (index) => {
    let { virtualStores } = this.state;
    let serviceAreas = virtualStores[index].serviceAreas;
    this.setState({ serviceAreas, showServiceLocations: true });
  };

  hideServiceLocations = () => {
    this.setState({ showServiceLocations: false });
  };

  render() {
    let { virtualStores = [] } = this.state;
    return (
      <div>
        <h3> Previously Visited Stores</h3>
        {virtualStores.length >= 1 ? (
          <div className="row">
            {virtualStores.map((virtualStore, index) => {
              return (
                <div className="col-md-3">
                  <div className="card mb-1">
                    <div
                      className="card-body d-flex"
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <h3
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.openVirtualStore(
                            virtualStore.code,
                            virtualStore.name,
                            virtualStore.status
                          );
                        }}
                        className="heading heading-5 strong-500"
                      >
                        {virtualStore.name}
                      </h3>

                      <div
                        className="btn-link"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.showServiceLocations(index)}
                      >
                        <i
                          className="fa fa-map-marker"
                          style={{ marginRight: "5px" }}
                        />
                        Locations
                      </div>
                    </div>
                    {virtualStore.status == "INACTIVE" && (
                      <small
                        className="d-flex justify-content-center "
                        style={{ color: "red" }}
                      >
                        <em>This store is not available anymore</em>
                      </small>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <p className="mt-5 px-5" style={{ fontSize: 16 }}>
                  You have not visited any stores.
                </p>
              </div>
            </div>
          </div>
        )}
        {this.state.showServiceLocations && (
          <ModalN
            title="Service Locations"
            show={this.state.showServiceLocations}
            close={this.hideServiceLocations}
          >
            <ServiceLocationsView serviceAreas={this.state.serviceAreas} />
          </ModalN>
        )}
      </div>
    );
  }
}

function actions(dispatch) {
  return {
    toastMessage: (message, type, duration) => {
      dispatch(Actions.toastMessage(message, type, duration));
    },
  };
}

export default connect(null, actions)(VirtualStoresView);
