import React from "react";
import { connect } from "react-redux";
import Order from "./../orders/Order";
import { fetchOrders } from "../../state/actions/order";

class OrdersView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { orders: [] };
  }

  componentDidMount() {
    this.props.fetchOrders();
  }

  getCount = () => {
    var orders = this.props.orders;
    if (!orders || orders.length === 0) {
      return (
        <div style={{ textAlign: "center" }}>
          <div>No orders have been created yet.</div>
          <div>Let us know what you need in the chat.</div>
        </div>
      );
    } else {
      return (
        <div style={{ textAlign: "center" }}>
          <div>{orders.length} Order(s)</div>
        </div>
      );
    }
  };

  render() {
    if (!this.props.orders) {
      return null;
    }

    var orderNodes = this.props.orders.map(function(order) {
      return (
        <div className="col-md-12" key={order._id}>
          <Order order={order} />
        </div>
      );
    });
    return (
      <div>
        <h5 className="dashboard-title">Orders</h5>
        {this.getCount()}
        {orderNodes}
      </div>
    );
  }
}

function data(state) {
  return {
    orders: state.orders ? state.orders.items : [],
    isFetching: state.orders ? state.orders.isFetching : false
  };
}

function actions(dispatch) {
  return {
    fetchOrders: () => dispatch(fetchOrders())
  };
}

export default connect(
  data,
  actions
)(OrdersView);
