//import { createStore, applyMiddleware } from 'redux'
//import thunkMiddleware from 'redux-thunk'
//var reducers = require('../reducers');
//var {persistStore, autoRehydrate} = require('redux-persist');
//var {AsyncStorage} = require('react-native');
//
//var createBsStore = applyMiddleware(thunkMiddleware)(createStore);
//function configureStore(onComplete) {
//    // TODO(frantic): reconsider usage of redux-persist, maybe add cache breaker
//    const store = autoRehydrate()(createBsStore)(reducers);
//    persistStore(store, {storage: AsyncStorage}, onComplete);
//    return store;
//}
//export default configureStore;

//
import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import reducers from "../reducers/index";

var configureStore = function(initialState = {}) {
  return createStore(reducers, initialState, applyMiddleware(thunkMiddleware));
};

export default configureStore;
