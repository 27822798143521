import React, { Component } from "react";
import Error from "../../widgets/alerts/Error";
import SignInForm from "../auth/SignInForm";

class SignInFormContainer extends Component {
  render() {
    return (
      <div>
        <Error message={this.props.error} style={{ margin: "10px" }} />

        <div className="col-md-10 col-md-offset-1">
          <SignInForm onSubmit={this.props.signIn} btnSize="btn-xs" />
        </div>
      </div>
    );
  }
}

export default SignInFormContainer;
