import React from "react";
import VirtualBuyerService from "../../../services/VirtualBuyerService";
import StorageService from "../../../services/StorageService";

class EditQuantity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeQuantity = (e, index) => {
    let { storeId } = this.props;
    let quantity = e.target.value;

    let serviceArea = StorageService.getFromLocalStorage("serviceArea");
    VirtualBuyerService.calculatePricingForVirtualBuyer(
      quantity,
      this.props.product.sku._id,
      serviceArea,
      "VIRTUAL_STORE",
      storeId
    ).then(response => {
      if (response.success) {
        this.setState({ data: response.data, success: true });
      } else {
        this.setState({ message: response.msg, success: false });
      }
    });

    this.setState({ quantity: quantity, success: false });
  };

  setEditQuantity = () => {
    this.setState({
      editQuantity: true,
      quantity: this.props.product.quantity
    });
  };

  closeEditQuantity = () => {
    this.setState({
      editQuantity: false,
      quantity: null
    });
  };

  updateCart = () => {
    let { quantity, data } = this.state;
    let { priceWithTax, totalPrice } = data;

    let { product } = this.props;
    product.quantity = quantity;
    product.priceWithTax = priceWithTax;
    product.totalPrice = totalPrice;

    this.props.updateCart(product);
    this.setState({ editQuantity: false });
  };

  render() {
    let { product } = this.props;
    return (
      <div>
        {!this.state.editQuantity ? (
          <div className="d-flex align-items-center">
            <span>{product.quantity}</span>
            <a className="btn-link" onClick={this.setEditQuantity}>
              <i className="fa fa-pencil-square-o" style={{ marginLeft: 5 }} />
            </a>
          </div>
        ) : (
          <div>
            <div className="d-flex flex-wrap">
              <input
                type="number"
                className="form-control input-number mr-1 mb-1"
                style={{
                  border: "1px solid hsl(0,0%,80%)",
                  borderRadius: "4px",
                  height: "28px",
                  padding: "2px 8px",
                  width: "75px"
                }}
                placeholder="Enter Quantity"
                value={this.state.quantity}
                onChange={e => this.onChangeQuantity(e)}
              />

              <button
                className="btn btn-sm btn-success"
                style={{ height: "28px" }}
                onClick={this.updateCart}
                disabled={!this.state.success}
              >
                <i className="fa fa-check" />
              </button>

              <button
                className="btn btn-sm btn-light"
                style={{ height: "28px", marginLeft: "5px" }}
                onClick={this.closeEditQuantity}
              >
                <i className="fa fa-times" />
              </button>
            </div>
            {!this.state.success && this.state.message && (
              <div className="text-danger">{this.state.message}</div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default EditQuantity;
